import { LoadingButton } from "@mui/lab"
import { Box, Grid, Skeleton, Stack, TextField, Typography } from "@mui/material"
import { useDashboardQuotas } from "hooks/dashboard.hooks"
import { useSnackbar } from "notistack"
import { useMemo, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useQueryClient } from "react-query"
import { queryKeys } from "util/constants/queryKeys"
import { setFinishedOrders, setQuota } from "util/helpers/apiHelpers"

export const Quotas = () => {
    const {isLoading: isQuotaLoading, data: quotaData} = useDashboardQuotas()
    const [isSaveLoading, setSaveLoading] = useState(false)
    const queryClient = useQueryClient()
    const { enqueueSnackbar } = useSnackbar()

    const {
        control,
        handleSubmit,
        formState: {errors, isValid},
        reset
    } = useForm({
        defaultValues: {
            targetQuota: '',
            finishedItems: ''
        },
        mode: 'onBlur'
    })

    const _onSubmit = async (newData) => {
        const payload = {
            quota: newData.targetQuota,
            finished: newData.finishedItems
        }
        const promises = []

        setSaveLoading(true)

        if (payload.quota) {
            promises.push(setQuota(payload.quota))
        }

        if (payload.finished) {
            promises.push(setFinishedOrders(payload.finished))
        }

        if (promises.length) {
            const resp = await Promise.all(promises)

            resp.forEach((response) => {
                if (response.success) {
                    queryClient.invalidateQueries(queryKeys.dashboardQuotas)
                    enqueueSnackbar(response.data.message, {variant: 'success'})
                } else {
                    enqueueSnackbar(response.data.message, {variant: 'error'})
                }
            })
        }

        setSaveLoading(false)
    }

    if (isQuotaLoading) {
        return <Skeleton variant={'rectangular'} height={250} flex={1}/>
    }

    return (
        <Box>
            <Stack direction="column" spacing={1}>
                <Typography variant="h5" sx={{fontWeight: 'bold', textAlign: 'left'}}>
                    Quotas
                </Typography>
                <Typography sx={{color: 'text.secondary', textAlign: 'left'}}>
                    Set Quotas and Finished Items
                </Typography>

                <Box sx={{mt: 4}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Controller 
                                name="targetQuota"
                                control={control}
                                render={({field: {name, value, onChange, onBlur}}) => 
                                    <TextField 
                                        name={name}
                                        value={value} 
                                        onChange={onChange} 
                                        onBlur={onBlur} 
                                        label={'Target Quota'}
                                        type="text"
                                        fullWidth
                                        helperText={`Current Quota: ${quotaData.quotas?.quota || 10}`} 
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller 
                                name="finishedItems"
                                control={control}
                                render={({field: {name, value, onChange, onBlur}}) => 
                                    <TextField 
                                        name={name}
                                        value={value} 
                                        onChange={onChange} 
                                        onBlur={onBlur} 
                                        label={'Finished Items'}
                                        fullWidth
                                        helperText={`Current items finished: ${quotaData.quotas.current}`} 
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{alignSelf: 'flex-end', pt: 2}}>
                    <LoadingButton loading={isSaveLoading} variant="contained" onClick={handleSubmit(_onSubmit)}>
                        Save
                    </LoadingButton>
                </Box>
            </Stack>
        </Box>
    )
}