import { styled, Dialog } from '@mui/material'

const StyledDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: `${theme.spacing(2)} !important`,
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2)
    }
}));

export { StyledDialog }