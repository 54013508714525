import { createElement, useState, useMemo, useEffect } from 'react'
import { StyledPaper } from "components/StyledPaper"
import { Stack, Typography, Box, List, ListItem, ListItemButton, ListItemText, Divider, ListItemIcon } from "@mui/material"
import { SETTINGS_MENU } from "util/constants/settings"
import * as Icons from '@mui/icons-material'
import { useNavigate, useLocation, Outlet } from 'react-router-dom'
import { useLogout } from 'hooks/auth.hooks'

const Settings = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const locationPath = useMemo(() => location.pathname.replace('/settings/', ''), [location.pathname])
    const { doLogout } = useLogout()

    const [selected, setSelected] = useState(locationPath === '/settings' ? 'products' : locationPath)
    
    const _handleClick = (key) => {
        navigate(key)
        setSelected(key)
    }

    useEffect(() => {
        if (locationPath === '/settings') {
            setSelected('products')
        }
    }, [locationPath, setSelected])

    return (
        <Box sx={{m: '16px', mt: '32px'}}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              Settings
            </Typography>
            <StyledPaper flex={1} sx={{width: '100%', padding: '0 32px 0 0', ml: 0, mr: 0}}>
                <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    {/* Menus */}
                    <List>
                        {SETTINGS_MENU.map((setting) => (
                            <ListItem key={setting.key} disablePadding>
                                <ListItemButton selected={setting.key === selected} onClick={() => _handleClick(setting.key)} >
                                    <ListItemIcon>
                                        {createElement(Icons[setting.icon])}
                                    </ListItemIcon>
                                    <ListItemText 
                                        primaryTypographyProps={{
                                            color: 'white'
                                        }}
                                        primary={setting.displayName} 
                                        secondary={setting.subHeader} 
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}

                            <ListItem key={'logout'} disablePadding sx={{mt: 4}}>
                                <ListItemButton onClick={doLogout} >
                                    <ListItemIcon>
                                        {createElement(Icons['Logout'], {color: 'error'})}
                                    </ListItemIcon>
                                    <ListItemText 
                                        primaryTypographyProps={{
                                            color: 'error'
                                        }}
                                        primary={'Logout'} 
                                        secondary={''} 
                                    />
                                </ListItemButton>
                            </ListItem>
                    </List>

                    <Box sx={{flexGrow: 1, p: 3, height: '100%'}}>
                        <Outlet />
                    </Box>
                </Stack>
                
            </StyledPaper>
        </Box>
    )
}

export default Settings