import React, { useMemo } from 'react';
import { Typography, Button, TextField, Stack, Divider } from '@mui/material';
import { StyledPaper } from 'components/StyledPaper';
import { API } from 'api';
import { API_ENDPOINTS } from 'util/constants/api';
import { EnquiryData } from 'util/types/enquiry-types';
import { parseISO } from 'date-fns';

interface EnquiryCommentsProps {
    enquiryData: EnquiryData;
}

const EnquiryComments = ({ enquiryData }: EnquiryCommentsProps) => {
    const commentList = useMemo(() => {
        if (!enquiryData?.comments) return [];

        return enquiryData.comments;
    }, [enquiryData]);

    return (
        <StyledPaper elevation={6} sx={{ flex: 1 }}>
            <Stack height={'100%'} direction="column" justifyContent="flex-start" divider={<Divider flexItem />} spacing={1}>
                <Typography align="center" variant="h6" fontWeight={'bold'}>
                    Enquiry Feedbacks
                </Typography>
                <Stack direction="column" spacing={0.5} sx={{ paddingTop: '12px', paddingBottom: '12px' }}>
                    {commentList.length === 0 && <Typography align="center">No Feedbacks</Typography>}

                    {commentList.map((data, index) => (
                        <StyledPaper
                            key={index}
                            elevation={1}
                            sx={{ flex: 1, alignSelf: 'end', borderRadius: '8px', minWidth: '12rem' }}
                        >
                            <Typography variant="body2" sx={{ opacity: 0.85 }}>
                                {parseISO(data.time).toLocaleDateString('en-AU', {
                                    year: '2-digit',
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                })}
                            </Typography>
                            <Divider sx={{ mb: 2 }} />
                            <Typography align="right" key={index} sx={{ whiteSpace: 'pre-wrap' }}>
                                {data.comment}
                            </Typography>
                        </StyledPaper>
                    ))}
                </Stack>
            </Stack>
        </StyledPaper>
    );
};

export default EnquiryComments;
