import { LoadingButton } from "@mui/lab"

const { Close } = require("@mui/icons-material")
const { Button, DialogTitle, IconButton, DialogContent, Typography, DialogActions, Box } = require("@mui/material")
const { StyledDialog } = require("components/StyledDialog")
const { useState } = require("react")

const SimpleModal = ({modalTitle='', dialogMsg = '', onConfirm, btnText = '', buttonProps, isLoading = false }) => {
    const [ isOpen, setOpen ] = useState(false)

    return (
        <div>
            <Button { ...buttonProps } onClick={() => setOpen(true)}>
                {btnText}
            </Button>
            <StyledDialog open={isOpen} onClose={() => setOpen(false)} maxWidth='sm' fullWidth>
                <DialogTitle sx={{m: 0, pt: 2, alignItems: 'center', display: 'flex'}}>
                    {modalTitle}
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            marginLeft: 'auto',
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <Box sx={{pl: 1}}>
                        <Typography>
                            {dialogMsg}
                        </Typography>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button variant="text" sx={{marginRight: 'auto'}} onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading} variant="contained" onClick={onConfirm}>
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </StyledDialog>
        </div>
    )
}

export default SimpleModal