import OTable from 'components/Tables/OTable';
import { useDespatch, useHistory } from 'hooks/pages.hooks';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import SkeletonLoader from 'components/SkeletonLoader';
import Error from 'components/ErrorComponent';
import { DespatchRow, HistoryRow } from 'components/Tables/Rows';
import { Box, Button, Stack, Typography } from '@mui/material';
import { API } from 'api';
import { API_ENDPOINTS } from 'util/constants/api';
import { usePermission } from 'hooks/permission.hooks';
import { useMemo } from 'react';
import { ROLES } from 'util/constants/permissions';
import { PermissionBarrier } from 'components/Permissions';
import { debounce } from 'util/helpers/debounce';
import { TableSearch } from 'components/Tables/TableSearch';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ActionModal } from 'components/Modals';

function History() {
    const { enqueueSnackbar } = useSnackbar()
    const { roleSwitch } = usePermission()
    const [searchParams, setSearchParams] = useSearchParams()

    const stateDefaults = useMemo(() => {
        const hasPage = searchParams.has('page')
        const hasLimit = searchParams.has('limit')
        const hasSearch = searchParams.has('search')

        let builder = {page: 0, limit: 25, search: ''}

        if (hasPage) {
            const queryPage = searchParams.get('page')

            if (queryPage >= 1) {
                builder.page = queryPage - 1
            }
        }

        if (hasLimit) {
            const limit = parseInt(searchParams.get('limit'), 10)

            if (limit === 25 || limit === 50 || limit === 100) {
                builder.limit = limit                
            }
        }

        if (hasSearch) {
            const querySearch = searchParams.get('search')

            builder.search = querySearch
        }

        return builder
    }, [searchParams])

    const [page, setPage] = useState(stateDefaults.page)
    const [rowsPerPage, setRowsPerPage] = useState(stateDefaults.limit)
    const [searchText, setSearchText] = useState(stateDefaults.search)

    const { isLoading, error, data, isError, isFetching, refetch } = useHistory({
        page: page + 1,
        limit: rowsPerPage,
        search: searchText
    },{
		onSuccess: (data) => {
			enqueueSnackbar('History fetched', {variant: 'success', key: 'historySnack'})
		},
		onError: (data) => {
			enqueueSnackbar(data.message, {variant: 'error', key: 'historySnack'})
		}
	})

    const tableColumns = useMemo(() => {
        return [
            {
                id: 'orderNo',
                numeric: 'true',
                label: 'Ord#'
            },
            {
                id: 'customerName',
                numeric: 'false',
                label: 'Customer'
            },
            {
                id: 'clientNo',
                numeric: 'false',
                label: 'Client Ord#'
            },
            {
                id: 'jobDesc',
                numeric: 'false',
                label: 'Description'
            },
            {
                id: 'actions',
                numeric: 'false',
                label: 'Actions'
            },
            {
                id: 'dueDate',
                numeric: 'false',
                label: roleSwitch({
                    [ROLES.wholesaler]: 'Target Date',
                    default: 'Deadline'
                })
            }
        ]
    }, [roleSwitch])

	useEffect(() => {
		if (isFetching && !isLoading) {
			enqueueSnackbar('Refetching...', {variant: 'info', key: 'historySnack'})
		}
	}, [isFetching, isLoading, enqueueSnackbar])
    

    const handlePageChange = useCallback((event, newPage) => {
        setPage(newPage)
        setSearchParams({
            page: newPage + 1,
            limit: rowsPerPage,
            search: searchText
        })
    }, [rowsPerPage, searchText, setSearchParams])

    const handleRowsPerPageChange = useCallback((event) => {
        const newLimit = parseInt(event.target.value, 10)
        setRowsPerPage(newLimit)
        setPage(0)
        setSearchParams({
            page: 1,
            limit: newLimit,
            search: searchText
        })

    }, [searchText, setSearchParams])

    // search component is already debounced...
    const _handleSearch = useCallback((text) => {
        setSearchText(text)
        setPage(0)
        setSearchParams({
            page: 1,
            limit: rowsPerPage,
            search: text
        })
    }, [setSearchParams, rowsPerPage])

	return (
		<>
            <Box sx={{marginTop: 4}}>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                    History
                </Typography>

                <TableSearch  
                    onSearch={_handleSearch}
                    defaultSearch={searchParams.get('search')}
                />

			    {isLoading && <SkeletonLoader />}

                {!isError && !isLoading && 
                    <OTable 
                        rows={data[1]}
                        headCells={tableColumns}
                        curPage={'history'}
                        extraColSpan={9}
                        sortOrder={'desc'}
                        paginate
                        paginationProps={{
                            page,
                            rowsPerPage,
                            totalCount: data[0].total,
                            handlePageChange,
                            handleRowsPerPageChange,
                        }}
                        renderRow={(props) => <HistoryRow {...props}/>}
                    /> 
                }
            </Box>
            
            <ActionModal.ImageList currPage={'history'} />
		</>
	);
}

export default History;
