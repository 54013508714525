import { ProductTypeOptions } from 'components/Modals/products.data';

export const COMPANY_OPTIONS = [
    {
        code: 'revolvashade',
        displayName: 'Revolvashade',
    },
    {
        code: 'clevershade',
        displayName: 'Clevershade',
    },
    {
        code: 'blacksheep',
        displayName: 'Blacksheep',
    },
    {
        code: 'all',
        displayName: 'All',
    },
];

export const MODEL_FIELDS = {
    modelCode: { name: 'modelCode', label: 'Code', required: true },
    modelName: { name: 'modelName', label: 'Name', required: true },
    productType: { name: 'productType', label: 'Product Type', required: true, options: ProductTypeOptions },
    weight: { name: 'weight', label: 'Weight', required: true },
    size: { name: 'size', label: 'Size', required: true },
    company: { name: 'company', label: 'Company', required: true, visible: true, options: COMPANY_OPTIONS },
    price: { name: 'price', label: 'Price', required: true },
    xeroID: { name: 'xeroID', label: 'Xero ID', required: true },
};
