import { cloneElement } from 'react'
import useAuthStore from 'store/auth'

export const hasPermission = ({ roleScope, role }) => {
    const scopesMap = {}

    roleScope.forEach((scope) => {
        scopesMap[scope] = true
    })

    return !!scopesMap[role]
}

export const PermissionBarrier = ({
    scopes = [],
    prohibitedProps,
    children,
    exclude,
    exception = false
}) => {
    const userRole = useAuthStore(state => state.role)

    const permissionGranted = hasPermission({ roleScope: scopes, role: userRole })
    const isExcluded = hasPermission({ roleScope: [exclude], role: userRole })

    if (exception) return <>{children}</>
    
    if ((!permissionGranted && !prohibitedProps) || isExcluded) return null

    if (!permissionGranted && prohibitedProps) return cloneElement(children, { ...prohibitedProps })

    return <>{children}</>
}