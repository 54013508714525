import { useEffect, useState } from 'react'
import { TextField, Typography, Box, Stack, Divider, Autocomplete } from '@mui/material'
import { useFrames, useProductData } from 'hooks/lookups.hooks'
import { useForm, Controller} from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { API } from 'api'
import { API_ENDPOINTS } from 'util/constants/api'
import { useSnackbar } from 'notistack'

export const Frames = () => {
    const { data: framesLookup, refetch: refetchFramesLookup} = useFrames()
    const { enqueueSnackbar } = useSnackbar()
    const [selectedFrame, setSelectedFrame] = useState(null)
    const [isCreateLoading, setCreateLoading] = useState(false)
    const [isModifyLoading, setModifyLoading] = useState(false)

    const { 
        control: createControl,
        handleSubmit: handleCreateSubmit,
        formState: { errors: createErrors, isValid: isCreateValid },
        reset: resetCreate
    } = useForm({
        defaultValues: {
            code: '',
            displayName: '',
        },
        mode: 'onBlur'
    })

    const {
        control: modifyControl,
        handleSubmit: handleModifySubmit,
        formState: { errors: modifyErrors, isValid: isModifyValid },
        resetField,
        setValue,
        reset: resetModify
    } = useForm({
        defaultValues: {
            code: '',
            displayName: ''
        },
        mode: 'onBlur'
    })

    const formRules = {required: 'This field is required'}

    const _handleFrameChange = (event, param) => {
        setSelectedFrame(param)

    }

    const _onSubmitCreate = async (newData) => {
        // API here...
        setCreateLoading(true)

        const { data } = await API.post(API_ENDPOINTS.CREATE_FRAME, { formData: newData })

        if (data.success) {
            refetchFramesLookup()
            enqueueSnackbar(data.message, { variant: 'success' })
            resetCreate()
        } else {
            enqueueSnackbar(data.message, { variant: 'error' })
        }

        setCreateLoading(false)
    }

    const _onSubmitModify = async (newData) => {
        setModifyLoading(true)
        const payload = {
            ...newData,
        }
        
        const { data } = await API.put(`${API_ENDPOINTS.MODIFY_FRAME}/${selectedFrame.id}`, { formData: payload })

        if (data.success) {
            refetchFramesLookup()
            enqueueSnackbar(data.message, { variant: 'success'})
            resetModify()
            setSelectedFrame(null)
        } else {
            enqueueSnackbar(data.message, { variant: 'error'})
        }

        setModifyLoading(false)
    }

    useEffect(() => {
        if (selectedFrame) {
            setValue('code', selectedFrame?.code, { shouldValidate: true, shouldDirty: true})
            setValue('displayName', selectedFrame?.displayName, { shouldValidate: true, shouldDirty: true})
        } else {
            resetField('code')
            resetField('displayName')
        }
    }, [selectedFrame, resetField, setValue])

    return (
        <Box>
            <Stack direction="column" spacing={1}> 
                <Typography variant="h5" sx={{fontWeight: 'bold', textAlign: 'left'}}>
                    Create Frame
                </Typography>
                <Typography sx={{color: 'text.secondary', textAlign: 'left'}}>
                    Create and define a new frame colour
                </Typography>

                <Stack direction="column" spacing={2}>
                    <Controller 
                        name="code"
                        control={createControl}
                        rules={formRules}
                        render={({ field }) => 
                            <TextField 
                                {...field} 
                                error={!!createErrors[field.name]} 
                                label={'Code'}
                                helperText={createErrors[field.name]?.message}/>
                        }
                    />
                    
                    <Controller 
                        name="displayName"
                        control={createControl}
                        rules={formRules}
                        render={({field}) => 
                            <TextField
                                {...field}
                                label={'Name'}
                                error={!!createErrors[field.name]} 
                                helperText={createErrors[field.name]?.message} 
                            />
                        }
                    />
                </Stack>

                <Box sx={{alignSelf: 'flex-end', pt: 2}}>
                    <LoadingButton loading={isCreateLoading} disabled={!isCreateValid} variant="contained" onClick={handleCreateSubmit(_onSubmitCreate)}>
                        Create
                    </LoadingButton>
                </Box>
            </Stack>

            <Divider sx={{mt: 4, mb: 4}}/>

            <Stack direction="column" spacing={1}> 
                <Typography variant="h5" sx={{fontWeight: 'bold', textAlign: 'left'}}>
                    Modify Frame
                </Typography>
                <Typography sx={{color: 'text.secondary', textAlign: 'left'}}>
                    Modify an existing frame
                </Typography>

                <Stack direction="column" spacing={2}>
                    <Autocomplete 
                        name={'frames'}
                        value={selectedFrame}
                        onChange={_handleFrameChange}
                        clearOnBlur
                        handleHomeEndKeys
                        options={framesLookup?.frames || []}
                        groupBy={(option) => option?.range}
                        getOptionLabel={(option) => option.displayName}
                        isOptionEqualToValue={(option, value) => option._id === value._id}
                        renderOption={(props, option) => <li {...props} key={option._id}>{option.displayName}</li>}
                        renderInput={(params) => <TextField {...params} label={'Frames'} />}
                    />

                    <Controller 
                        name="code"
                        control={modifyControl}
                        rules={formRules}
                        render={({field: {name, value, onChange, onBlur}}) => 
                            <TextField 
                                name={name}
                                value={value} 
                                onChange={onChange} 
                                onBlur={onBlur} 
                                label={'Code'}
                                type="text"
                                error={!!modifyErrors[name]} 
                                helperText={modifyErrors[name]?.message} 
                            />
                        }
                    />

                    <Controller 
                        name="displayName"
                        control={modifyControl}
                        rules={formRules}
                        render={({field: {name, value, onChange, onBlur}}) => 
                            <TextField 
                                name={name}
                                value={value} 
                                onChange={onChange} 
                                onBlur={onBlur} 
                                label={'Name'}
                                error={!!modifyErrors[name]} 
                                helperText={modifyErrors[name]?.message} 
                            />
                        }
                    />
                </Stack>

                <Box sx={{alignSelf: 'flex-end', pt: 2}}>
                    <LoadingButton variant="contained" loading={isModifyLoading} disabled={!isModifyValid} onClick={handleModifySubmit(_onSubmitModify)}>
                        Modify
                    </LoadingButton>
                </Box>
            </Stack>
        </Box>
    )
}