import { useState, useMemo, useEffect } from 'react';
import {
    Button,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Grid,
    IconButton,
    Autocomplete,
    Typography,
    Link,
    Box,
    Stack,
} from '@mui/material';
import { StyledDialog } from 'components/StyledDialog';
import { Close } from '@mui/icons-material';

interface CustomerDetailsModalProps {
    isOpen: boolean;
    onClose: () => void;
    customerData: {
        customerName: string;
        customerEmail: string;
        customerNumber: string;
    };
}

const CustomerDetailsModal = ({ isOpen, onClose, customerData }: CustomerDetailsModalProps) => {
    const handleClose = () => {
        onClose();
    };

    return (
        <StyledDialog open={isOpen} maxWidth="sm" fullWidth onClose={handleClose}>
            <DialogTitle sx={{ m: 0, pt: 2, alignItems: 'center', display: 'flex', textTransform: 'capitalize' }}>
                {`Customer Details`}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        marginLeft: 'auto',
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ px: 1 }}>
                    <Grid item xs={6} direction="row">
                        <Stack direction="row" spacing={1}>
                            <Typography variant="body1" sx={{ opacity: 0.7 }}>
                                Name:
                            </Typography>
                            <Typography variant="body1">{customerData.customerName}</Typography>
                        </Stack>
                    </Grid>

                    <Grid item xs={6} direction="row">
                        <Stack direction="row" spacing={1}>
                            <Typography variant="body1" sx={{ opacity: 0.7 }}>
                                Phone:
                            </Typography>
                            <Link href={`tel:${customerData.customerNumber}`} variant="body1">
                                {customerData.customerNumber}
                            </Link>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} direction="row">
                        <Stack direction="row" spacing={1}>
                            <Typography variant="body1" sx={{ opacity: 0.7 }}>
                                Email:
                            </Typography>
                            <Link href={`mailto:${customerData.customerEmail}`} variant="body1">
                                {customerData.customerEmail}
                            </Link>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </StyledDialog>
    );
};

export default CustomerDetailsModal;
