import useAuthStore from "store/auth";

export const usePermission = () => {
  const userRole = useAuthStore((state) => state.role);

  const roleSwitch = (roleActions) => {
    if (roleActions[userRole]) {
      return roleActions[userRole];
    }

    return roleActions.default;
  };

  return {
    roleSwitch,
  };
};
