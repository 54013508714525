import { Box } from '@mui/material'

export const TabPanel = ({ children, value, index, style, ...others}) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`products-panel-${index}`}
            style={style}
            aria-labelledby={`products-panel-${index}`}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            )}
        </div>
    )
}