import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
// import { SearchProvider } from "./contexts/searchContext";
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import AppRoute from 'routes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
            refetchInterval: 300 * 3000,
            retry: 5,
            refetchOnReconnect: 'always', // because cached query never goes stale...
        },
    },
});

const snackbarProps = {
    maxSnack: 3,
    preventDuplicate: true,
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
};

export default function App() {
    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <QueryClientProvider client={queryClient}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <SnackbarProvider {...snackbarProps}>
                        <AppRoute />
                    </SnackbarProvider>
                    {/* should only be there for testing not production */}
                    <ReactQueryDevtools initialIsOpen={false} />
                </LocalizationProvider>
            </QueryClientProvider>
        </ThemeProvider>
    );
}
