export const stringRandomizer = (text, length = 0) => {
    let result = '';

    const baseLength = 5;

    for (let i = 0; i < baseLength + length; i++) {
        result += text.charAt(Math.floor(Math.random() * text.length));
    }

    return result;
};
