import {useState, useMemo, useEffect} from 'react';
import {
    Button, DialogContent, DialogActions, DialogTitle,
    TextField , Grid,
    IconButton,
    Autocomplete
} from "@mui/material"
import { LoadingButton } from '@mui/lab'
import { useForm, Controller } from 'react-hook-form'
import { StyledDialog } from 'components/StyledDialog';
import { API } from 'api';
import { API_ENDPOINTS } from 'util/constants/api';
import { useSnackbar } from 'notistack';
import { Close } from '@mui/icons-material';
import { debounce } from 'util/helpers/debounce';
import { DUE_DATE_FORMAT } from 'util/constants/dates';
import { DatePicker } from '@mui/x-date-pickers';
import { useQueryClient } from 'react-query';
import { queryKeys } from 'util/constants/queryKeys';
import { createEnquiry, updateEnquiry } from 'util/helpers/apiHelpers';

const EnquiryFormModal = ({ isOpen, isModify, formData, onClose }) => {
    const { enqueueSnackbar } = useSnackbar()
    const queryClient = useQueryClient()

    const { 
        control,
        handleSubmit,
        reset,
        setValue,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: {
            customerName: "",
            contactNumber: '',
            contactEmail: '',
            retailer: '',
            dueDate: '',
            enquiryDetails: ''
        },
        mode: 'onBlur'
    })

    const [isLoading, setIsLoading] = useState(false)
    
    const [userList, setUserList] = useState([])
    const [isSearchLoading, setIsSearchLoading] = useState(false)

    const formRules = useMemo(() => ({required: 'This field is required'}), [])

    const _onSubmit = async (formData) => {
        setIsLoading(true)

        const payload = {
            formData: {
                retailer: formData.retailer.id,
                email: formData.contactEmail,
                number: formData.contactNumber,
                customer: formData.customerName,
                information: formData.enquiryDetails,
                dueDate: formData.dueDate
            }
        }

        let response 

        if (isModify) {
            response = await updateEnquiry(formData.enq_id, payload)
        } else {
            response = await createEnquiry(payload)
        }

        
        if (response.success) {
            queryClient.resetQueries([queryKeys.enquiryData])
            enqueueSnackbar(`Enquiry ${isModify ? 'Updated' : 'Created'}`, { variant: 'success'})
            handleClose()
        } else {
            enqueueSnackbar(`Failed to ${isModify ? 'update' : 'create'} enquiry`, { variant: 'error'})
        }

        setIsLoading(false)
    }

    const _handleInputChange = debounce( async (event, value, reason = 'input') => {
        if (reason !== 'input') return

        if (value) {
            setIsSearchLoading(true)

            const { data } = await API.get(`${API_ENDPOINTS.GET_USERS}?username=${value}`)

            setIsSearchLoading(false)

            if (data.success) {
                setUserList(data.users)
            }
        }
    })

    const handleClose = () => {
        onClose()

        reset()
    }

    useEffect(() => {
        if (isModify && formData && isOpen) {
            Object.keys(formData).forEach(key => {
                setValue(key, formData[key])   
            })
        }
    }, [isModify, formData, setValue, isOpen])

    return (
        <StyledDialog
            open={isOpen}
            maxWidth="sm"
            fullWidth
            onClose={handleClose}>
            <DialogTitle sx={{m: 0, pt: 2, alignItems: 'center', display: 'flex', textTransform: 'capitalize'}}>
                {`Enquiry Form`}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        marginLeft: 'auto',
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form id="createEnquiry" onSubmit={handleSubmit(_onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Controller
                                    name={'retailer'}
                                    control={control}
                                    rules={formRules}
                                    render={({
                                        field: {name, value, onChange, onBlur}
                                    }) => (
                                        <Autocomplete 
                                            name={name}
                                            value={value || null}
                                            onChange={(event, param) => onChange(param)}
                                            onBlur={onBlur}
                                            options={userList}
                                            clearOnBlur
                                            placeholder="Search Retailer Name "
                                            loading={isSearchLoading}
                                            loadingText={'Fetching retailer...'}
                                            noOptionsText={'No retailer found'}
                                            fullWidth
                                            handleHomeEndKeys
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            onInputChange={_handleInputChange}
                                            getOptionLabel={(option) => option.username}
                                            renderOption={(props, option) => <li {...props} key={option.id}>{option.username}</li>}
                                            renderInput={(params) => <TextField {...params} placeholder="Search Retailer" label="Retailer Name *" error={!!errors?.retailer} helperText={errors?.retailer?.message}  />}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller 
                                    name={'dueDate'}
                                    control={control}
                                    rules={formRules}
                                    render={({field: {name, value, onChange, onBlur}}) => (
                                        <DatePicker 
                                            label={'Due Date'}
                                            mask={'__-__-____'}
                                            value={value}
                                            onChange={(date) => {
                                                onChange(date)
                                            }}
                                            name={name}
                                            onClose={onBlur}
                                            inputFormat={DUE_DATE_FORMAT}
                                            renderInput={(params) => 
                                                <TextField 
                                                    {...params} 
                                                    onBlur={onBlur}
                                                    fullWidth 
                                                    error={!!errors?.dueDate}
                                                    helperText={errors?.dueDate?.message}
                                                />
                                            }
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Controller 
                                    name={'customerName'}
                                    control={control}
                                    defaultValue=""
                                    rules={formRules}
                                    render={({ field: {name, value, onChange, onBlur}}) => (
                                        <TextField 
                                            label={'Customer Name'} 
                                            name={name} 
                                            fullWidth
                                            value={value} 
                                            onChange={onChange} 
                                            onBlur={onBlur}
                                            error={!!errors[name]} 
                                            helperText={errors[name]?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <></>
                            </Grid>
                            <Grid item xs={6}>
                                <Controller 
                                    name={'contactNumber'}
                                    control={control}
                                    defaultValue=""
                                    rules={formRules}
                                    render={({ field: {name, value, onChange, onBlur}}) => (
                                        <TextField 
                                            label={'Customer Phone Number'} 
                                            name={name} 
                                            fullWidth
                                            value={value} 
                                            onChange={onChange} 
                                            onBlur={onBlur}
                                            error={!!errors[name]} 
                                            helperText={errors[name]?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller 
                                    name={'contactEmail'}
                                    control={control}
                                    defaultValue=""
                                    rules={formRules}
                                    render={({ field: {name, value, onChange, onBlur}}) => (
                                        <TextField 
                                            label={'Customer Email'} 
                                            name={name} 
                                            fullWidth
                                            value={value} 
                                            onChange={onChange} 
                                            onBlur={onBlur}
                                            error={!!errors[name]} 
                                            helperText={errors[name]?.message}
                                        />
                                    )}
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <Controller 
                                    name={'enquiryDetails'}
                                    control={control}
                                    defaultValue=""
                                    rules={formRules}
                                    render={({ field: {name, value, onChange, onBlur}}) => (
                                        <TextField 
                                            label={'Enquiry Details'} 
                                            name={name} 
                                            value={value} 
                                            onChange={onChange} 
                                            onBlur={onBlur}
                                            fullWidth
                                            minRows={4}
                                            multiline
                                            error={!!errors[name]} 
                                            helperText={errors[name]?.message}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton 
                    type="submit" 
                    loading={isLoading}
                    disabled={!isValid}
                    form="createEnquiry" 
                    variant="contained">
                    Submit
                </LoadingButton>
            </DialogActions>
        </StyledDialog>
    )
}

export default EnquiryFormModal
