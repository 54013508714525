import { mutator } from 'util/helpers/mutator';
import { FeedbackModalDataType, ProductModalType } from 'util/types/enquiry-types';
import { create } from 'zustand';

interface ModalState<T> {
    isModalOpen: boolean;
    modalData: T;
    setModalOpen: (isOpen: boolean) => void;
    setModalData: (data: T) => void;
}

function createModalStore<T>() {
    return create<ModalState<T>>()((set) => ({
        isModalOpen: false,
        modalData: {} as T,
        setModalOpen: mutator(set, 'isModalOpen'),
        setModalData: mutator(set, 'modalData'),
    }));
}

export const useFeedbackModal = createModalStore<FeedbackModalDataType>();

export const useProductModal = createModalStore<ProductModalType>();
