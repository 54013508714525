import { useState, useEffect } from 'react'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Divider, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { debounce } from 'util/helpers/debounce'
import { API } from 'api'
import { API_ENDPOINTS } from 'util/constants/api'
import { useSnackbar } from 'notistack'

const RoleOptions = ['Admin', 'Sales', 'Wholesaler']

export const Accounts = () => {
    const { enqueueSnackbar } = useSnackbar()
    const [selectedUser, setSelectedUser] = useState(null)
    const [isCreateLoading, setCreateLoading] = useState(false)
    const [isModifyLoading, setModifyLoading] = useState(false)
    const [userList, setUserList] = useState([])
    const [clientList, setClientList] = useState([])
    const [isSearchLoading, setIsSearchLoading] = useState(false)

    const { 
        control: createControl,
        handleSubmit: handleCreateSubmit,
        formState: { errors: createErrors, isValid: isCreateValid },
        reset: resetCreate
    } = useForm({
        defaultValues: {
            username: '',
            password: '',
            optoAccount: null,
            roles: ''
        },
        mode: 'onBlur'
    })

    const { 
        control: modifyControl,
        handleSubmit: handleModifySubmit,
        formState: { errors: modifyErrors, isValid: isModifyValid },
        reset: resetModify,
        setValue
    } = useForm({
        defaultValues: {
            username: '',
            password: '',
            optoAccount: '',
            roles: ''
        },
        mode: 'onBlur'
    })

    const formRules = { required: 'This field is required' } 

    const _onSubmit = async (formData) => {
        const payload = {
            ...formData,
            optoAccount: formData.optoAccount
        }

        setCreateLoading(true)
        const { data } = await API.post(API_ENDPOINTS.CREATE_USER, { formData: payload })

        if (data.success) {
            enqueueSnackbar(data.message, { variant: 'success' })
            resetCreate()
        } else {
            enqueueSnackbar(data.message, {variant: 'error' })
        }

        setCreateLoading(false)
    }

    const _onModify = async (formData) => {
        const payload = {
            ...formData,
            optoAccount: formData.optoAccount,
            id: selectedUser.id
        }

        setModifyLoading(true)
        const { data } = await API.put(`${API_ENDPOINTS.MODIFY_USER}/${payload.id}`, { formData: payload })

        if (data.success) {
            enqueueSnackbar(data.message, { variant: 'success' })
            resetModify()
            setSelectedUser(null)
        } else {
            enqueueSnackbar(data.message, {variant: 'error' })
        }

        setModifyLoading(false)
    }

    const _handleInputChange = debounce( async (value, reason = 'input', mode = 'user') => {
        if (reason !== 'input') return

        if (value) {
            setIsSearchLoading(true)
            const endpoint = mode === 'user' ? `${API_ENDPOINTS.GET_USERS}?username=${value}` : `${API_ENDPOINTS.GET_CLIENTS}?name=${value}`
            // api...
            const { data } = await API.get(endpoint)

            if (data.success) {
                if (mode === 'user') {
                    setUserList(data.users)
                } else {
                    setClientList(data.clients)
                }
            }

            setIsSearchLoading(false)
        }
    })

    useEffect(() => {
        if (selectedUser) {
            setValue('username', selectedUser.username, { shouldValidate: true, shouldDirty: true })
            setValue('optoAccount', selectedUser.optoAccount, { shouldValidate: true, shouldDirty: true })
            setValue('roles', selectedUser.role, {shouldValidate: true, shouldDirty: true})
        } else {
            resetModify()
        }
    }, [selectedUser, setValue, resetModify])

    return (
        <Box>
            <Typography variant="body2" sx={{textAlign: 'left', color: '#90caf9', padding: '12px 16px'}}>
                ACCOUNTS
            </Typography>
            <Divider sx={{borderColor: '#90caf9', borderWidth: '1px'}}/>

            <Box sx={{p: 3}}>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5" sx={{fontWeight: 'bold', textAlign: 'left'}}>
                        Create Account
                    </Typography>
                    <Typography sx={{color: 'text.secondary', textAlign: 'left'}}>
                        Create a new account
                    </Typography>

                    <Stack direction="column" spacing={2}>
                        <Box>
                            <form id="createAccount" onSubmit={handleCreateSubmit(_onSubmit)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Controller 
                                            name={'username'}
                                            control={createControl}
                                            rules={formRules}
                                            render={({ field }) => 
                                                <TextField  
                                                    {...field} 
                                                    label="Username" 
                                                    fullWidth 
                                                    autoComplete='off'
                                                    error={!!createErrors['username']} 
                                                    helperText={createErrors['username']?.message}
                                                />
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Controller 
                                            name={'password'}
                                            control={createControl}
                                            rules={formRules}
                                            render={({ field }) => 
                                                <TextField 
                                                    {...field} 
                                                    label="Password" 
                                                    fullWidth 
                                                    autoComplete='off'
                                                    type="password"
                                                    error={!!createErrors['password']} 
                                                    helperText={createErrors['password']?.message}
                                                />
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Controller
                                            name={'optoAccount'}
                                            control={createControl}
                                            rules={formRules}
                                            render={({
                                                field: {name, value, onChange, onBlur}
                                            }) => (
                                                <Autocomplete 
                                                    name={name}
                                                    value={value || null}
                                                    onChange={(event, param) => onChange(param)}
                                                    onBlur={onBlur}
                                                    options={clientList}
                                                    clearOnBlur
                                                    placeholder="Search Opto Account "
                                                    loading={isSearchLoading}
                                                    loadingText={'Fetching client...'}
                                                    noOptionsText={'No client found'}
                                                    fullWidth
                                                    handleHomeEndKeys
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    onInputChange={(event, value, reason) => _handleInputChange(value, reason, 'client')}
                                                    getOptionLabel={(option) => option.clientName}
                                                    renderOption={(props, option) => <li {...props} key={option._id}>{option.clientName}</li>}
                                                    renderInput={(params) => <TextField {...params} placeholder="Search Opto Account" label="Opto Account" error={!!createErrors?.optoAccount} helperText={createErrors?.optoAccount?.message}  />}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Controller 
                                            name="roles"
                                            control={createControl}
                                            rules={formRules}
                                            render={({
                                                field: {name, value, onChange, onBlur}
                                            }) => (
                                                <TextField 
                                                    name={name} 
                                                    value={value || ''} 
                                                    onChange={onChange} 
                                                    onBlur={onBlur}
                                                    sx={{textAlign: 'left'}}
                                                    fullWidth
                                                    select
                                                    label="Role"
                                                    error={!!createErrors[name]}
                                                    helperText={createErrors[name]?.message}
                                                >
                                                    {RoleOptions.map((option, index) => (
                                                        <MenuItem key={index} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </Grid>
                                </Grid> 
                            </form>

                        </Box>

                        <Box sx={{alignSelf: 'flex-end', pt: 2}}>
                            <LoadingButton type="submit" form="createAccount" loading={isCreateLoading} disabled={!isCreateValid} variant="contained" >
                                Create
                            </LoadingButton>
                        </Box>
                    </Stack>
                </Stack>

                <Divider sx={{mt: 4, mb: 4}}/>
                
                
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5" sx={{fontWeight: 'bold', textAlign: 'left'}}>
                        Modify Account
                    </Typography>
                    <Typography sx={{color: 'text.secondary', textAlign: 'left'}}>
                        modify existing account
                    </Typography>


                    <Stack direction="column" spacing={2}>
                        <Autocomplete 
                            name={'userSelect'}
                            value={selectedUser}
                            options={userList}
                            clearOnBlur
                            placeholder="Search User "
                            loading={isSearchLoading}
                            loadingText={'Fetching user...'}
                            noOptionsText={'No user found'}
                            fullWidth
                            handleHomeEndKeys
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, param) => setSelectedUser(param)}
                            onInputChange={(event, value, reason) => _handleInputChange(value, reason)}
                            getOptionLabel={(option) => option.username}
                            renderOption={(props, option) => <li {...props} key={option.id}>{option.username}</li>}
                            renderInput={(params) => <TextField {...params} placeholder="Search User" label="Search User" />}
                        />

                        {selectedUser && (
                            <>
                                <Box>
                                    <form id="modifyAccount" onSubmit={handleModifySubmit(_onModify)}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Controller 
                                                    name={'username'}
                                                    control={modifyControl}
                                                    formRules={formRules}
                                                    render={({ field }) => 
                                                        <TextField 
                                                            {...field} 
                                                            label="Username" 
                                                            fullWidth                                                     
                                                            autoComplete='off'
                                                            error={!!modifyErrors['username']} 
                                                            helperText={modifyErrors['username']?.message}
                                                        />
                                                    }
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Controller 
                                                    name={'password'}
                                                    control={modifyControl}
                                                    formRules={formRules}
                                                    render={({ field }) => 
                                                        <TextField 
                                                            {...field} 
                                                            label="New Password" 
                                                            fullWidth 
                                                            autoComplete='off'
                                                            type="password"
                                                            error={!!modifyErrors['password']} 
                                                            helperText={modifyErrors['password']?.message}
                                                        />
                                                    }
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Controller
                                                    name={'optoAccount'}
                                                    control={modifyControl}
                                                    rules={formRules}
                                                    render={({
                                                        field: {name, value, onChange, onBlur}
                                                    }) => (
                                                        <Autocomplete 
                                                            name={name}
                                                            value={value || null}
                                                            onChange={(event, param) => onChange(param)}
                                                            onBlur={onBlur}
                                                            options={clientList}
                                                            clearOnBlur
                                                            placeholder="Search Opto Account "
                                                            loading={isSearchLoading}
                                                            loadingText={'Fetching client...'}
                                                            noOptionsText={'No client found'}
                                                            fullWidth
                                                            handleHomeEndKeys
                                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                                            onInputChange={(event, value, reason) => _handleInputChange(value, reason, 'client')}
                                                            getOptionLabel={(option) => option.clientName}
                                                            renderOption={(props, option) => <li {...props} key={option._id}>{option.clientName}</li>}
                                                            renderInput={(params) => <TextField {...params} placeholder="Search Opto Account" label="Opto Account" error={!!modifyErrors?.optoAccount} helperText={modifyErrors?.optoAccount?.message}  />}
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Controller 
                                                    name="roles"
                                                    control={modifyControl}
                                                    rules={formRules}
                                                    render={({
                                                        field: {name, value, onChange, onBlur}
                                                    }) => (
                                                        <TextField 
                                                            name={name} 
                                                            value={value || ''} 
                                                            onChange={onChange} 
                                                            onBlur={onBlur}
                                                            sx={{textAlign: 'left'}}
                                                            fullWidth
                                                            select
                                                            label="Role"
                                                            error={!!modifyErrors[name]}
                                                            helperText={modifyErrors[name]?.message}
                                                        >
                                                            {RoleOptions.map((option, index) => (
                                                                <MenuItem key={index} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    )}
                                                />
                                            </Grid>
                                        </Grid> 
                                    </form>

                                </Box>

                                <Box sx={{alignSelf: 'flex-end', pt: 2}}>
                                    <LoadingButton type="submit" form="modifyAccount" loading={isModifyLoading} disabled={!isModifyValid} variant="contained" >
                                        Modify
                                    </LoadingButton>
                                </Box>
                            </>
                        )}
                    </Stack>
                </Stack>
            </Box>
        </Box>
    )
}