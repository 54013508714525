import { useQuery } from 'react-query';
import { API } from 'api';
import { API_ENDPOINTS } from 'util/constants/api';
import { ProductTypeOptions } from 'components/Modals/products.data';
import { queryKeys } from 'util/constants/queryKeys';

const fetchProductData = async (endpoint, queryParam = '') => {
    const { data } = await API.get(`${API_ENDPOINTS[endpoint]}${queryParam}`);

    if (data.success && endpoint === 'FETCH_LOOKUP') {
        const categorizedModels = ProductTypeOptions.reduce((result, item, index, array) => {
            result[item.code] = data.models.filter((model) => model.product === item.displayName);

            return result;
        }, {});

        data.models = categorizedModels;
    }

    if (data.success && endpoint === 'GET_PCFORMS') {
        const { PCForm, models } = data.PCForms;

        const forms = PCForm.filter((form) => !!form.name).map((pcForm) => {
            const modelData = models.find((model) => model.code === pcForm.name);
            // index based traversal
            // all arrays in a pcForm MUST have the same length
            // bad data otherwise which should have been filtered already...
            const length = Math.floor(pcForm.mMetal.length, pcForm.mNames.length, pcForm.mSizes.length);
            let rowDataAcc = [];
            for (let index = 0; index < length; index++) {
                rowDataAcc.push({
                    mMetal: pcForm.mMetal[index],
                    mName: pcForm.mNames[index],
                    mSize: pcForm.mSizes[index],
                    mAmount: pcForm.mAmount[index],
                });
            }

            return {
                modelData,
                rowData: rowDataAcc,
                _id: pcForm._id,
            };
        });

        data.PCForms = forms;
    }

    if (data.success && endpoint === 'GET_TIMESHEETS') {
        data.outputPay = Object.keys(data.outputPay).map((key) => {
            return { ...data.outputPay[key] };
        });
    }

    if (data.success && endpoint === 'GET_DAYWORK') {
        const { entries, orders } = data;

        const mergedEntryAndOrders = orders.map((order) => {
            let activityHolder = [];
            // Search all the _id matching order umbrellas in entries...
            order.umbrellas.forEach((umbrella, index) => {
                const matchedActivities = entries
                    .filter((entry) => entry._id === umbrella)
                    .map((entry) => ({ ...entry, orderIdx: `${order.orderNo}-${index + 1}` }));

                activityHolder = activityHolder.length ? activityHolder.concat(matchedActivities) : matchedActivities;
            });

            return {
                ...order,
                activity: activityHolder,
            };
        });

        data.mergedData = mergedEntryAndOrders;
    }

    return data;
};

export const useProductData = () => {
    // return useQuery('productDataLookup', () => fetchProductData('FETCH_LOOKUP'), {
    //     staleTime: Infinity,
    //     refetchInterval: 300000,
    // });

    return { data: { colours: [], frames: [] } };
};

export const useModelData = (option) => {
    const queryParam = `?forms=${!!option?.hasForms}`;

    return useQuery('modelDataLookup', () => fetchProductData('GET_MODELS', queryParam), {
        staleTime: Infinity,
        refetchInterval: 300000,
    });
};

export const usePCFormsData = () => {
    return useQuery('pcFormsLookup', () => fetchProductData('GET_PCFORMS'), {
        staleTime: Infinity,
        refetchInterval: 300000,
    });
};

export const useTimesheetData = () => {
    return useQuery('timesheetLookup', () => fetchProductData('GET_TIMESHEETS'), {
        staleTime: Infinity,
        refetchInterval: 300000,
    });
};

export const usePartsData = () => {
    return useQuery('partsLookup', () => fetchProductData('FETCH_PARTS'), {
        staleTime: Infinity,
        refetchInterval: 300000,
    });
};

export const useFrames = () => {
    return useQuery('framesLookup', () => fetchProductData('GET_FRAMES'));
};

export const useColours = () => {
    return useQuery('coloursLookup', () => fetchProductData('GET_COLOURS'));
};

export const useWholesalerSettings = () => {
    // return useQuery('wholesalerSettings', () => fetchProductData('GET_WHOLESALER_WEEKS'), {
    //     staletime: Infinity,
    //     refetchInterval: 300000,
    // });

    return { data: { settings: {} } };
};

export const useActivityReportsData = (startDate, endDate, empId = '') => {
    const queryParam = `?startDate=${startDate}&endDate=${endDate}`;
    return useQuery(['activityReports', startDate, endDate, empId], () => fetchProductData('GET_DAYWORK', queryParam), {
        staleTime: 0,
        refetchInterval: 300000,
    });
};

export const useEmployeeData = (isActive) => {
    const queryParam = `?active=${isActive}`;

    return useQuery(['empData'], () => fetchProductData('GET_EMPLOYEES', queryParam), {
        staleTime: Infinity,
        refetchInterval: 300000,
    });
};

export const useImageList = (orderNo, queryOptions) => {
    return useQuery(['imageList', orderNo], () => fetchProductData(`GET_IMG`, `/${orderNo}`), {
        staleTime: Infinity,
        refetchInterval: 300000,
        ...queryOptions,
    });
};

export const useRetailerList = (queryOptions) => {
    return useQuery(queryKeys.retailerListData, () => fetchProductData('GET_USERS'), {
        staleTime: 30000,
        refetchInterval: 300000,
        ...queryOptions
    })
}