export const COLORS = {
    primary: '#90caf9',
    primary_darker: '#42a5f5',
    danger: '#f44336',
    success: '#4caf50',
    success_light: '#4caf5033',
    warning_primary: '#ffa726',
    warning_light: '#ffa72633',
    darkslategrey: '#2F4F4F',
    darkslategrey_light: '#2f4f4fb3',
    darkslateblue: '#483D8B',
    darkslateblue_light: '#483d8bb3',
    neutral_gray: '#212121',
    neutral_gray_base: '#9e9e9e',
    neutral_gray_light: '#424242',
};
