import { addWeeks, differenceInDays, isAfter, isThisWeek, isWithinInterval } from "date-fns"
import { useMemo } from "react"

const { Box, LinearProgress, Typography } = require("@mui/material")
const { COLORS } = require("util/constants/commons")

const ProgressBar = ({ progressPercent, dueDate }) => {
    
    const progressColor = useMemo(() => {
        let textColor = COLORS.neutral_gray
        let progressBarColor = 'primary'

        const overdueDays = differenceInDays(addWeeks(new Date(), 1), new Date(dueDate));
        const isWeekBefore = isThisWeek(new Date(dueDate))
        
        if (overdueDays > 7) {
            textColor = 'text.secondary'
            progressBarColor = 'error' 
        }

        // difference can get into negatives, so this is to make sure it's within 7 days before turning red.
        if (isWeekBefore || (overdueDays < 7 && overdueDays > 0)) {
            textColor = COLORS.neutral_gray
            progressBarColor = 'warning' 
        }

        if (progressPercent === 100) {
            progressBarColor = 'success'
            textColor = COLORS.neutral_gray
        }
        

        return {textColor, progressBarColor}
    }, [progressPercent, dueDate])

    return (
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Box sx={{'width': '100%'}}>
              <LinearProgress 
                    sx={{
                        height: '20px',
                        borderRadius: '2px'
                    }}
                    variant="determinate" 
                    value={progressPercent} 
                    color={progressColor.progressBarColor}
                />
            </Box>
            
            <Box sx={{position: 'absolute'}}>
                <Typography variant="body2" fontWeight={600} color={progressColor.textColor}>
                    {progressPercent + '%'}
                </Typography>
            </Box>

        </Box>
    )
}

export default ProgressBar