import { 
    DialogContent, 
    DialogTitle,
    Button, 
    TextField, 
    Stack, 
    Typography,
    DialogActions,
    IconButton,
    Tooltip,
    Grid,
} from "@mui/material"
import { LoadingButton } from '@mui/lab'
import { Close, Replay, Restore } from "@mui/icons-material"
import { useMemo, useState } from 'react'
import { StyledDialog } from 'components/StyledDialog'
import { useMutation, useQueryClient } from "react-query"
import { useSnackbar } from "notistack"
import { API } from "api"
import { API_ENDPOINTS } from "util/constants/api"
import { PermissionBarrier } from "components/Permissions"
import { ROLES } from "util/constants/permissions"

const ActionForm = ({ finishedBy, finishTime, orderNo, actionType, employeeInfo, shouldDisable}) => {
    const queryClient = useQueryClient()
    const [text, setText] = useState('')
    const { enqueueSnackbar } = useSnackbar()

    const setEmpNumber = (event) => {
        setText(event.target.value);
    }

    const mutation = useMutation(async (isUndo = false) => {
        let reqSite = API_ENDPOINTS.UPDATE_FINISHED_BY

        switch (actionType) {
            case "sewed": 
                reqSite += `?field=sewed&number=${text}&orderNo=${orderNo}&reset=${isUndo}`;
                break;
            case "cut":
                reqSite += `?field=cut&number=${text}&orderNo=${orderNo}&reset=${isUndo}`;
                break;
            case "assembled":
                reqSite += `?field=assembled&number=${text}&orderNo=${orderNo}&reset=${isUndo}`;
                break;
            case "packaged":
                reqSite += `?field=packaged&number=${text}&orderNo=${orderNo}&reset=${isUndo}`;
                break;

            case 'sentPC': 
                reqSite += `?field=sentpc&orderNo=${orderNo}&reset=${isUndo}`
                break;

            case 'receivedPC':
                reqSite += `?field=recpc&orderNo=${orderNo}&reset=${isUndo}`
                break;

            default:
        }
        
        return API.put(reqSite)
    }, {
        onSuccess: (data) => {
            enqueueSnackbar(data.data.message, {variant: 'success'})
            queryClient.invalidateQueries('orderBook')
            queryClient.invalidateQueries('despatch')
        },
        onError: (data) => {
            enqueueSnackbar(data.data.message, {variant: 'error'})
        }
    })

    const _getEmployee = useMemo(() => {
        if (!employeeInfo) {
            return ''
        }

        return employeeInfo.find(emp => emp.employeeNumber === finishedBy)
    }, [finishedBy, employeeInfo])

    const renderEmployeeActions = () => {
        return (
            <>
            {finishedBy !== 0 ? 
                <Stack direction="row" spacing={1} alignItems={'center'}>
                    <Tooltip title={`Finished by: ${_getEmployee?.employeeName} at ${new Date(finishTime).toLocaleString()}`}>
                        <Typography>
                            {_getEmployee?.employeeName} - {new Date(finishTime).toLocaleString()}
                        </Typography> 
                    </Tooltip>
                    <Tooltip title={'Undo this action'}>
                        <LoadingButton
                            size="large"
                            aria-label="undo chosen action"
                            aria-controls="action-button"
                            aria-haspopup="false"
                            startIcon={<Replay />}
                            onClick={() => mutation.mutate(true)}
                            color="error"
                        >
                            Undo
                        </LoadingButton>
                    </Tooltip>
                </Stack>

                :

                <Stack direction="row" spacing={1}>
                    <PermissionBarrier scopes={[ROLES.admin, ROLES.sales, ROLES.factory]}>
                        <TextField 
                            id={orderNo} 
                            label={'Emp. No'} 
                            sx={{width: '15ch'}} 
                            size="small" 
                            onChange={setEmpNumber}
                        />

                        <LoadingButton disabled={!text} loading={mutation.isLoading} onClick={() => mutation.mutate()}>
                            Submit
                        </LoadingButton>
                    </PermissionBarrier>

                    <PermissionBarrier scopes={[ROLES.wholesaler]}>
                        <Typography>
                            Not Finished
                        </Typography>
                    </PermissionBarrier>
                </Stack>
            }
        </>
        )
    }

    return (
        <>
            {actionType === 'sentPC' || actionType === 'receivedPC' ? (
                <> 
                {finishTime !== 0 ? 
                    <Stack direction="row" spacing={1} alignItems={'center'}>
                            <Typography>
                                {`${actionType === 'sentPC' ? 'Sent' : 'Received'}`} - {new Date(finishTime).toLocaleString()}
                            </Typography> 
                        <Tooltip title={'Undo this action'}>
                            <LoadingButton
                                size="large"
                                aria-label="undo chosen action"
                                aria-controls="action-button"
                                aria-haspopup="false"
                                startIcon={<Replay />}
                                onClick={() => mutation.mutate(true)}
                                color="error"
                                loading={mutation.isLoading}
                                disabled={shouldDisable && (actionType === 'sentPC' || finishTime === 0)}
                            >   
                                Undo
                            </LoadingButton>
                        </Tooltip>
                    </Stack>

                    :

                    <Stack direction="row" spacing={1}>
                        <PermissionBarrier scopes={[ROLES.admin, ROLES.sales, ROLES.factory]}>
                            <LoadingButton disabled={shouldDisable} loading={mutation.isLoading} onClick={() => mutation.mutate()}>
                                {actionType === 'sentPC' ? 'Send' : 'Received'}
                            </LoadingButton>
                        </PermissionBarrier>

                        <PermissionBarrier scopes={[ROLES.wholesaler]}>
                            <Typography>
                                Not {actionType === 'sentPC' ? 'Not Sent' : 'Not Received'}
                            </Typography>
                        </PermissionBarrier>
                    </Stack>
                }
                </>
            ) : renderEmployeeActions()}
        </>
    )
}


const EmployeeActionsModal = ({ orderNo, productIndex, actionProps, completed }) => {
    // Modal control
    const [isOpen, setOpen] = useState(false)

    return (
        <div>
            <Button onClick={(e) => {
                e.stopPropagation()
                setOpen(true)
            }} variant="outlined" size="small" sx={{padding: '2px 12px'}}>
                {`View`}
            </Button>
            <StyledDialog onClick={(e) => e.stopPropagation()} open={isOpen} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
                <DialogTitle sx={{m: 0, pt: 2, alignItems: 'center', display: 'flex'}}>
                    {`Actions for Order #${orderNo}-${productIndex}`}
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            marginLeft: 'auto',
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <Grid container spacing={2} direction={"column"}>
                        <Grid item xs container spacing={2}>
                            <Grid item xs container spacing={2}>
                                <Grid item xs={12}>
                                    <Stack direction="column" spacing={1}>
                                        <Typography>
                                            Sewed By:
                                        </Typography>
                                        <ActionForm 
                                            finishedBy={actionProps.sewed}
                                            employeeInfo={actionProps.employeeInfo}
                                            finishTime={actionProps.sewedWhen}
                                            orderNo={actionProps.orderId}
                                            actionType="sewed"
                                        />
                                    </Stack>
                                </Grid>

                                <Grid item xs={12}>
                                    <Stack direction="column" spacing={1}>
                                        <Typography>
                                            Cut by:
                                        </Typography>
                                        <ActionForm 
                                            finishedBy={actionProps.cut}
                                            employeeInfo={actionProps.employeeInfo}
                                            finishTime={actionProps.cutWhen}
                                            orderNo={actionProps.orderId}
                                            actionType="cut"
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid item xs container spacing={2}>
                                <Grid item xs={12}>
                                    <Stack direction="column" spacing={1}>
                                        <Typography>
                                            Assembled by:
                                        </Typography>
                                        <ActionForm 
                                            finishedBy={actionProps.assembled}
                                            employeeInfo={actionProps.employeeInfo}
                                            finishTime={actionProps.assembledWhen}
                                            orderNo={actionProps.orderId}
                                            actionType="assembled"
                                        />
                                    </Stack>
                                </Grid>

                                <Grid item xs={12}>
                                    <Stack direction="column"  spacing={1}>
                                        <Typography>
                                            Packaged by:
                                        </Typography>
                                        <ActionForm 
                                            finishedBy={actionProps.packaged}
                                            employeeInfo={actionProps.employeeInfo}
                                            finishTime={actionProps.packagedWhen}
                                            orderNo={actionProps.orderId}
                                            actionType="packaged"
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs container spacing={2}>
                            <Grid item xs container spacing={2}>
                                <Grid item xs={6}>
                                    <Stack direction="column" spacing={1}>
                                        <Typography>
                                            Send to Powder Coating?
                                        </Typography>
                                        <ActionForm 
                                            finishTime={actionProps.sentWhen}
                                            orderNo={actionProps.orderId}
                                            actionType="sentPC"
                                            shouldDisable={actionProps.receivedWhen > 0}
                                        />
                                    </Stack>
                                </Grid>

                                <Grid item xs={6}>
                                    <Stack direction="column" spacing={1}>
                                        <Typography>
                                            Receive from Powder Coating?
                                        </Typography>
                                        <ActionForm 
                                            finishTime={actionProps.receivedWhen}
                                            orderNo={actionProps.orderId}
                                            actionType="receivedPC"
                                            shouldDisable={actionProps.sentWhen === 0}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button 
                        variant="text"
                        onClick={() => {
                            setOpen(false)
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </StyledDialog>
        </div>
    )
}

export default EmployeeActionsModal