import { KeyboardArrowDown } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { enquiryAction } from 'util/helpers/apiHelpers';
import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { queryKeys } from 'util/constants/queryKeys';
import { useFeedbackModal, useProductModal } from 'store/modals';
import { ActionFlows, EnquiryData, FeedbackModalDataType, ProductModalType } from 'util/types/enquiry-types';
import { COLORS } from 'util/constants/commons';

interface EnquiryActionsProps {
    rowData: EnquiryData;
}

const CONVERTED: ActionFlows[] = [
    {
        id: 'CV-0',
        displayName: 'Converted',
        actionName: 'Customer Approved',
        value: true,
        tag: 'converted',
    },
    {
        id: 'CV-1',
        displayName: 'Rejected Enquiry',
        actionName: 'Customer Rejected',
        value: false,
        tag: 'converted',
        openFeedbackModal: true,
    },
];

const CONTACTED_FLOW: ActionFlows[] = [
    {
        id: 'C-00',
        displayName: 'Customer Approved?',
        actionName: 'Add Products',
        value: true,
        tag: 'quoted',
        options: CONVERTED,
        openProductModal: true,
    },
    {
        id: 'C-01',
        displayName: 'Rejected',
        actionName: 'Customer Rejected',
        value: false,
        tag: 'quoted',
        openFeedbackModal: true,
    },
];

const ACCEPTED_FLOW: ActionFlows[] = [
    {
        id: 'AF-00',
        displayName: 'Add Quoted Products?',
        actionName: 'Customer Contacted',
        value: true,
        tag: 'contacted',
        openFeedbackModal: true,
        options: CONTACTED_FLOW,
    },
    {
        id: 'AF-01',
        displayName: 'Contact Failed',
        actionName: 'Contact Failed',
        value: false,
        tag: 'contacted',
        openFeedbackModal: true,
    },
];

const ASSIGNED_FLOW: ActionFlows[] = [
    {
        id: 'A-01',
        displayName: 'Contacted Customer?',
        actionName: 'Accept Enquiry',
        value: true,
        tag: 'accepted',
        options: ACCEPTED_FLOW,
    },
    {
        id: 'A-02',
        actionName: 'Reject Enquiry',
        tag: 'accepted',
        value: false,
    },
];

const BASE_FLOW: ActionFlows[] = [
    {
        id: 'B-00',
        displayName: 'Unassigned',
        actionName: 'Unassigned',
        value: false,
    },
    {
        id: 'B-01',
        displayName: 'Accept Enquiry?',
        actionName: 'Assigned',
        value: true,
        options: ASSIGNED_FLOW,
    },
];

export const EnquiryActions = ({ rowData }: EnquiryActionsProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedAction, setSelectedAction] = useState<ActionFlows>(BASE_FLOW[0]);
    const [isActionLoading, setIsActionLoading] = useState(false);
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const openFeedbackModal = useFeedbackModal((state) => state.setModalOpen);
    const setFeedbackModalData = useFeedbackModal((state) => state.setModalData);

    const openProductModal = useProductModal((state) => state.setModalOpen);
    const setProductModalData = useProductModal((state) => state.setModalData);

    const open = Boolean(anchorEl);

    const getStateFromData = useCallback(() => {
        let currentState: ActionFlows = BASE_FLOW[1];
        const enqStatus = rowData.status;

        // IF no retailer assigned
        console.log(rowData, ' uwu');
        if (!rowData.assigned_wholesaler) {
            currentState = BASE_FLOW[0];

            // Return here to skip the loop check
            setSelectedAction(currentState);
            return;
        }

        for (const key in enqStatus) {
            if (!enqStatus[key as keyof typeof enqStatus]) continue; // Skip if the status is false
            switch (key) {
                case 'accepted':
                    currentState = ASSIGNED_FLOW[0];
                    break;
                case 'contacted':
                    currentState = ACCEPTED_FLOW[0];
                    break;
                case 'quoted':
                    currentState = enqStatus.contacted ? CONTACTED_FLOW[0] : ACCEPTED_FLOW[1];
                    break;
                case 'converted':
                    currentState = CONVERTED[0];
                    break;
                default:
                    continue; // Skip unexpected keys
            }
        }

        setSelectedAction(currentState);
    }, [
        rowData.status.accepted,
        rowData.status.converted,
        rowData.status.contacted,
        rowData.status.quoted,
        rowData.assigned_wholesaler,
    ]);

    const handleClickActions = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, action: ActionFlows) => {
        event.stopPropagation();

        handleClose(event);

        // Don't call API until a product has been submitted
        if (action?.openProductModal) {
            // Store reducer call here to trigger target modal open
            // pass the rowData.enq_id and openModal: true
            const modalData: ProductModalType = {
                action,
                enquiryData: rowData,
                callback: processAction,
            };

            openProductModal(true);
            setProductModalData(modalData);
        } else if (action?.openFeedbackModal) {
            const modalData: FeedbackModalDataType = {
                enq_id: rowData.enquiry_id,
                action,
                callback: processAction,
            };

            openFeedbackModal(true);
            setFeedbackModalData(modalData);
        } else {
            processAction(action, rowData.enquiry_id);
        }
    };

    const processAction = async (action: ActionFlows, enquiryId: string) => {
        setIsActionLoading(true);
        const payload = {
            formData: {
                actionName: action.tag,
                actionData: action.value,
            },
        };

        try {
            const response = await enquiryAction(payload, enquiryId);
            if (response.success) {
                enqueueSnackbar('Action updated', { variant: 'success' });
                setSelectedAction(action);
                queryClient.refetchQueries(queryKeys.enquiryData);
            }
        } catch (e) {
            console.log(e, ':: Action Failed');
            enqueueSnackbar('Failed to update action', { variant: 'error' });
        } finally {
            setIsActionLoading(false);
        }
    };

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    useEffect(() => {
        getStateFromData();
    }, [getStateFromData]);

    return (
        <>
            <LoadingButton
                id="actionButton"
                aria-haspopup="listbox"
                aria-controls="lock-menu"
                aria-label="when device is locked"
                onClick={handleClickActions}
                sx={{
                    textTransform: 'capitalize',
                    pointerEvents: selectedAction?.options ? 'auto' : 'none',
                    color: rowData.status.converted ? COLORS.success : 'primary',
                }}
                loading={isActionLoading}
                endIcon={selectedAction?.options && <KeyboardArrowDown />}
            >
                {selectedAction?.displayName || ''}
            </LoadingButton>

            <Menu
                id="actionMenu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'actionButton',
                    role: 'listbox',
                }}
            >
                {selectedAction?.options &&
                    selectedAction.options.map((actions, index) => {
                        return (
                            <MenuItem key={actions.id} onClick={(event) => handleMenuItemClick(event, actions)}>
                                {actions.actionName}
                            </MenuItem>
                        );
                    })}
            </Menu>
        </>
    );
};
