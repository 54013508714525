import { API } from 'api'
import { API_ENDPOINTS } from 'util/constants/api'
import useAuthStore from 'store/auth'
import { useSnackbar } from 'notistack'
export const useLogout = () => {
    const { enqueueSnackbar } = useSnackbar()
    const resetAuth = useAuthStore(state => state.resetAuth)

    const doLogout = async () => {
        const { data } = await API.post(API_ENDPOINTS.LOGOUT)

        if (data.success) {
            enqueueSnackbar(data.message, {variant: 'success'})
            resetAuth()
        } else {
            enqueueSnackbar(data.message, {variant: 'error'})
        }
    }


    return { doLogout }
}