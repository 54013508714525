import {useState, useEffect} from 'react';
import {
    Button, DialogContent, DialogActions, DialogTitle,
    TextField , Grid, Box, IconButton
} from "@mui/material"
import { Edit } from '@mui/icons-material'
import { useForm, Controller } from 'react-hook-form'
import { StyledDialog } from 'components/StyledDialog';

const FIELDS = {
    mName: { label: 'Name', name: 'mName', required: true},
    mSize: { label: 'Size', name: 'mSize'},
    mMetal: { label: 'Metal Type', name: 'mMetal'},
    mAmount: {label: 'Amount', name: 'mAmount', type: 'number'},
}

export const PCEntry = ({ onSubmit, type = "add", entryData }) => {
    const [open, setOpen] = useState(false);

    const { 
        control,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        reset
    } = useForm({
        defaultValues: {
            mName: "",
            mSize: "",
            mMetal: "",
            mAmount: "",
        },
        mode: 'onBlur'
    })

    const formRules = {required: 'This field is required'}

    const _handleFormSubmit = (formData) => {
        if (type === 'edit') {
            formData.indexId = entryData.indexId
        }

        onSubmit(formData)
        setOpen(false)

        if (type !== 'edit') {
            reset()
        }
    }

    useEffect(() => {
        if (entryData) {
            setValue('mName', entryData?.mName, { shouldDirty: true, shouldValidate: true })
            setValue('mSize', entryData?.mSize)
            setValue('mMetal', entryData?.mMetal)
            setValue('mAmount', entryData?.mAmount)
        }
    }, [entryData, setValue])
    

    return (
        <>
            { type === 'add' ? 
                <Button variant="outlined" onClick={() => setOpen(true)}>Add Entry</Button> 
                : 

                <IconButton variant="contained" color="primary" onClick={() => setOpen(true)}>
                    <Edit />
                </IconButton>
            }
            <StyledDialog
                open={open}
                maxWidth="sm"
                fullWidth
                onClose={() => setOpen(false)}>
                <DialogTitle>PC Form Data</DialogTitle>
                <DialogContent>
                    <Box>
                        <form id="formEntry" onSubmit={handleSubmit(_handleFormSubmit)}>
                            <Grid container spacing={2} columnSpacing={2}>
                                {Object.keys(FIELDS).map((key) => {
                                    return (
                                        <Grid item xs={6} key={key}>
                                            <Controller 
                                                name={FIELDS[key].name}
                                                control={control}
                                                rules={FIELDS[key]?.required && formRules}
                                                defaultValue=""
                                                render={({ field: {name, value, onChange, onBlur}}) => (
                                                    <TextField 
                                                        fullWidth
                                                        label={FIELDS[key].label} 
                                                        name={name} 
                                                        value={value} 
                                                        onChange={onChange} 
                                                        onBlur={onBlur}
                                                        error={!!errors[name]}
                                                        helperText={errors[name]?.message}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </form>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button
                        type="submit" 
                        form="formEntry" 
                        disabled={!isValid}
                        variant="contained">
                        {type === 'add' ? 'Add' : 'Modify'}
                    </Button>
                </DialogActions>
            </StyledDialog>
        </>
    )
}