import { REACT_APP_BASE_URL } from "util/constants/api";
import useAuthStore from 'store/auth'

// receives AxiosInstance
const apiInterceptor = (instance) => {
    instance.interceptors.request.use(
        async (config) => {
            const token = useAuthStore.getState().token
            const isLoggedIn = useAuthStore.getState().isLoggedIn
            const isFirstTime = useAuthStore.getState().isFirstTime

            config.baseURL = REACT_APP_BASE_URL
            config.withCredentials = true
            
            if ((isLoggedIn || isFirstTime) && token) {
                config.headers['Authorization'] = `Bearer ${token}`
            }

            return config
        },
        (error) => {
            console.log('Error in request: ', error)

            // Do something with the error
            return {
                data: error.response.data,
                status: error.response.status
            }
        }
    ) 

    instance.interceptors.response.use(
        async (response) => {
            const responsePayload = {
                data: response.data,
                status: response.status
            }

            // Xero redirect
            if (responsePayload.data?.redirectURL && responsePayload.status === 200) {
                window.location.replace(responsePayload.data.redirectURL)
            }

            return responsePayload 
        },
        (error) => {
            const setLoggedIn = useAuthStore.getState().setLoggedIn
            const setAuthError = useAuthStore.getState().setAuthError

            const IS_TIMEOUT = error?.response?.status === 408 || error.code === 'ECONNABORTED'
            const errorPayload = {
                data: IS_TIMEOUT ? { message: 'Connection Timed out...', status: 408} : error.response?.data,
                status: IS_TIMEOUT ? 408 : error.response.status
            }

            if (error?.response?.status === 401) {
                setAuthError(error.response.data.message)
                setLoggedIn(false)
            }
            
            throw errorPayload;
        }
    )
}

export default apiInterceptor