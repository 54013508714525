import { useQuery } from 'react-query';
import { API } from 'api';
import { API_ENDPOINTS } from 'util/constants/api';

const fetchData = async (endpoint, queryParam = '') => {
    const { data, status } = await API.get(`${API_ENDPOINTS[endpoint]}${queryParam}`);

    // if (endpoint === 'ORDER_BOOK' && status === 200) {
    // will return a flattened 2D array
    // data.orderInfo = data.orderInfo
    //   .map((orders) => {
    //     return orders.umbrellas.map((umbrella, index) => ({
    //       productIndex: index + 1,
    //       orderNo: orders.orderNo,
    //       customerName: orders.customer.name,
    //       dueDate: orders.dueDate,
    //       cReference: orders.cReference,
    //       invNo: orders.invNo,
    //       orderDetails: { ...orders },
    //       ...umbrella,
    //     }));
    //   })
    //   .flat();
    // }

    return data;
};

export const useSales = (queryClientOptions) => {
    return useQuery('sales', () => fetchData('SALES'), queryClientOptions);
};

export const useDespatch = (queryClientOptions) => {
    return useQuery('despatch', () => fetchData('DESPATCH'), queryClientOptions);
};

export const useHistory = (paginationOptions, queryClientOptions) => {
    const queryParam = `?page=${paginationOptions.page || 1}&limit=${paginationOptions.limit || 25}&search=${
        paginationOptions.search || ''
    }`;
    return useQuery(
        ['history', paginationOptions.page, paginationOptions.limit, paginationOptions.search],
        () => fetchData('HISTORY', queryParam),
        queryClientOptions
    );
};

export const useOrderbook = (queryClientOptions) => {
    return useQuery('orderBook', () => fetchData('ORDER_BOOK'), queryClientOptions);
};
