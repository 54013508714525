import { Alert, AlertTitle, Box, Container, Typography, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "components/Header";
import { useColours, useFrames, useModelData, useProductData } from "hooks/lookups.hooks";
import { useAnnounceData } from "hooks/app.hooks";
import { useCallback, useMemo, useState } from "react";
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const MainLayout = () => {
    // Preload
    const { data: modelsDataLookup } = useModelData()    
    const { data: framesLookup } = useFrames()
    const { data: coloursLookup } = useColours()

    const { data: announceData, isLoading: isAnnounceLoading, isError } = useAnnounceData()
    const [activeStep, setActiveStep] = useState(0)
    const theme = useTheme()

    const handleStepChange = (step) => {
    setActiveStep(step);
    };

    const _renderAnnouncement = useCallback(() => {
        if (!announceData?.announcements || announceData?.announcements.length === 0 || isAnnounceLoading || isError) {
            return <></>
        }

        return (
            <Box>
                 <AutoPlaySwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                        interval={5000}
                    >
                        {announceData.announcements.map((data) => (
                            <Alert 
                                sx={{display: 'flex', alignContent: 'center', justifyContent: 'center'}} 
                                key={data.id} 
                                icon={false}
                                variant='filled' 
                                severity={data.type}
                            >

                                <AlertTitle sx={{fontWeight: 'bold'}}>
                                    {(data.message).toUpperCase()}
                                </AlertTitle>
                                
                            </Alert>
                        ))}
                </AutoPlaySwipeableViews>
            </Box>
        )
    }, [announceData, isAnnounceLoading, isError, activeStep, theme.direction])

    return (
        <main>
            {_renderAnnouncement()}
            <Header />
            <Container maxWidth="xl" sx={{paddingBottom: 6}}>
                <Outlet />
            </Container>
        </main>
    )
}

export default MainLayout