import { useQuery } from 'react-query';
import { API } from 'api';
import { API_ENDPOINTS } from 'util/constants/api';

const fetchAPIData = async (endpoint, queryParam = '') => {
    const { data } = await API.get(`${API_ENDPOINTS[endpoint]}${queryParam}`);

    return data;
};

export const useAnnounceData = () => {
    return useQuery('announceDataLookup', () => fetchAPIData('GET_ANNOUNCE'));
};
