import apiInterceptor from './interceptors';
import { REACT_APP_BASE_URL } from 'util/constants/api';
const axios = require('axios').default;

export const instance = axios.create({
    baseURL: REACT_APP_BASE_URL,
    timeout: 30 * 1000,
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
});

apiInterceptor(instance);

export const API = {
    post(endpoint, formData, config) {
        return instance.post(endpoint, formData, config);
    },

    put(endpoint, formData, config) {
        return instance.put(endpoint, formData, config);
    },

    patch(endpoint, formData, config) {
        return instance.patch(endpoint, formData, config);
    },

    get(endpoint, config) {
        return instance.get(endpoint, config);
    },

    delete(endpoint, config) {
        return instance.delete(endpoint, config);
    },
};
