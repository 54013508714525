import create from 'zustand';
import { persist } from 'zustand/middleware';
import { mutator } from 'util/helpers/mutator';

const initialState = {
    isLoggedIn: false,
    isFirstTime: false,
    expiryDate: null,
    token: null,
    authError: '',
    role: '',
    clientName: '',
    wholesalerSettings: null,
};

export default create(
    persist(
        (set) => ({
            ...initialState,
            setLoggedIn: mutator(set, 'isLoggedIn'),
            setFirstTime: mutator(set, 'isFirstTime'),
            setExpiryDate: mutator(set, 'expiryDate'),
            setToken: mutator(set, 'token'),
            setAuthError: mutator(set, 'authError'),
            setRole: mutator(set, 'role'),
            setClientName: mutator(set, 'clientName'),
            setWholesalerSettings: mutator(set, 'wholesalerSettings'),
            resetAuth: () => set(() => initialState),
        }),
        {
            name: 'auth-storage',
        }
    )
);
