import OTable from 'components/Tables/OTable';
import { ActionModal } from 'components/Modals';
import { useSales } from 'hooks/pages.hooks';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import SkeletonLoader from 'components/SkeletonLoader';
import Error from 'components/ErrorComponent';
import { SalesRow } from 'components/Tables/Rows';
import { Box, Button, Stack, Typography } from '@mui/material';
import { PermissionBarrier } from 'components/Permissions';
import { ROLES } from 'util/constants/permissions';
import { usePermission } from 'hooks/permission.hooks';
import { useMemo } from 'react';
import { TableSearch } from 'components/Tables/TableSearch';

const Sales = () => {
	const { isLoading, isError, error, data, isFetching, isFetched } = useSales({
		onSuccess: (data) => {
			enqueueSnackbar('Sales fetched', {variant: 'success', key: 'salesSnack'})
		},
		onError: (data) => {
			enqueueSnackbar(data.message, {variant: 'error', key: 'salesSnack'})
		}
	})
	const { enqueueSnackbar } = useSnackbar()
	const [isModalOpen, setModalOpen] = useState(false)
	const [searchText, setSearchText] = useState('')

	const tableColumns = useMemo(() => {
		return [
			{
				id: 'customer',
				numeric: 'false',
				label: 'Name'
			},
			{
				id: 'reference',
				numeric: 'false',
				label: 'Reference'
			},
			{
				id: 'action',
				numeric: 'false',
				label: 'Order No.'
			}
		]
	}, [])


	useEffect(() => {
		if (isFetching && !isLoading) {
			enqueueSnackbar('Refetching...', {variant: 'info', key: 'salesSnack'})
		}
	}, [isFetching, isLoading, enqueueSnackbar, isError, isFetched])


	// search component is already debounced...
	const _handleSearch = useCallback((text) => {
		setSearchText(text)
	}, [])

	
	return (
		<>
			<Stack direction="row" spacing={1} sx={{marginTop: 2}}>
				<Button onClick={() => setModalOpen(true)} variant="contained">
					{`Add Order`}
				</Button>

			</Stack>
			<Box sx={{marginTop: 4}}>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                    Current Orders
                </Typography>

                <TableSearch  
                    onSearch={_handleSearch}
                />

			    {isLoading && <SkeletonLoader />}

				{!isError && !isLoading && 
					<OTable 
						rows={data.orderInfo}
						headCells={tableColumns}
						curPage={'sales'}
						filterText={searchText}
						sortOrder={'desc'}
						renderRow={(props) => <SalesRow {...props}/>}
					/> 
				}
			</Box>
			

			<ActionModal.Order isOpen={isModalOpen} onClose={() => setModalOpen(false)} type="add" />
		</>
	);
}

export default Sales;
