import { Box, Tabs, Tab } from '@mui/material'
import { useState } from 'react'
import { Colours } from './Colours'
import { Frames } from './Frames'
import { Models } from './Models'
import { TabPanel } from 'components/TabPanel'

export const Products = () => {
    const [ tabValue, setTabValue ] = useState(0)

    const _handleChange = (event, newValue) => {
        setTabValue(newValue)
    }

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={tabValue} onChange={_handleChange} aria-label="products tabs">
                    <Tab label="Frames" id="product-frames" aria-controls="product-frames-tab" />
                    <Tab label="Colours" id="product-colours" aria-controls="product-colours-tab" />
                    <Tab label="Models" id="product-models" aria-controls="product-models-tab" />
                </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
                <Frames />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <Colours />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <Models />
            </TabPanel>
        </>
    )
}