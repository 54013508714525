import { Typography, Box, Stack, Alert, Button } from "@mui/material"
import EnquiryTable from "components/EnquirySections/EnquiryTable"
import { ActionModal } from "components/Modals"
import { PermissionBarrier } from "components/Permissions"
import { useState } from "react"
import { ROLES } from "util/constants/permissions"

const RetailerEnquiry = () => {
    // const [alertActive, setAlertActive] = useState(true)
    const [isFormOpen, setFormOpen] = useState(false)

    return (
        <Box sx={{m: '16px', mt: '32px'}}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              Enquiries
            </Typography>
            
            <Stack mt={6} spacing={2} direction={'column'}>
                {/* {alertActive && (
                    <Alert severity="error" variant="standard" sx={{border: 1, borderColor: '#852508'}} onClose={() => setAlertActive(false)}>
                        There are 45 Unread Messages from Retailers
                    </Alert>
                )} */}
                <PermissionBarrier scopes={[ROLES.admin, ROLES.sales]}>
                    <Box sx={{alignSelf: 'flex-start'}}>
                        <Button variant="contained" onClick={() => setFormOpen(true)}>
                            Create Enquiry
                        </Button>
                    </Box>
                </PermissionBarrier>
                <EnquiryTable />
            </Stack>

            <ActionModal.EnquiryForm isOpen={isFormOpen} onClose={() => setFormOpen(false)} />
        </Box>
    )
}

export default RetailerEnquiry