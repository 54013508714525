import {
    TableCell,  TableRow, 
    IconButton, Collapse, Typography, TextField, Checkbox, Stack,
    Button
} from '@mui/material'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { useMutation, useQueryClient } from 'react-query';
import { CommentList } from 'components/CommentsList';
import { Details } from 'components/Details';
import {useState, memo, useMemo, useCallback} from 'react';
import { API_ENDPOINTS } from 'util/constants/api';
import { API } from 'api';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { PermissionBarrier } from 'components/Permissions';
import { ROLES } from 'util/constants/permissions';
import { format, parse } from 'date-fns';
import { DISPLAY_FORMAT, SERVER_FORMAT } from 'util/constants/dates';
import useImageStore from 'store/images'
import { ActionModal } from 'components/Modals';

// const FinishComponent = memo((props) => {
//     const { orderNo, conNo } = props;
//     const queryClient = useQueryClient();
//     const [text, setText] = useState("");
//     const { enqueueSnackbar } = useSnackbar()

//     const setEmpNumber = (event) => {
//         setText(event.target.value);
//     }

//     const mutation = useMutation(() => {
//         let reqSite = `${API_ENDPOINTS.ADD_CONNOTE}?order=${orderNo}`

//         return API.post(reqSite, { conNo: text })
//     }, {
//         onSuccess: ({ data }) => {
//             enqueueSnackbar(data.message, { variant: 'success'})
//             queryClient.invalidateQueries('despatch')
//         },
//         onError: ({data}) => {
//             enqueueSnackbar(data.message, { variant: 'error'})
//         }
//     })

//     return (
//         <>
//             {conNo !== '' ? 
//                     <Typography>
//                         {conNo}
//                     </Typography>

//                 :

//                 <PermissionBarrier scopes={[ROLES.admin, ROLES.sales, ROLES.factory]}>
//                     <Stack direction="row" spacing={1}>
//                         <TextField 
//                             id={`${orderNo}`} 
//                             label={"Add Con#"} 
//                             sx={{width: '15ch'}} 
//                             size="small" 
//                             onChange={setEmpNumber}
//                         />

//                         <LoadingButton loading={mutation.isLoading} onClick={() => mutation.mutate()}>
//                             Submit
//                         </LoadingButton>
//                     </Stack>
//                 </PermissionBarrier>
//             }
//         </>
//     );
// })

const DespatchRow = ({row, comments, clientInfo, ...props}) => {
    const [open, setOpen] = useState(false);
    // const queryClient = useQueryClient();
    // const { enqueueSnackbar } = useSnackbar();
    // const authRole = useAuthStore(state => state.role)
    const setModalToggle = useImageStore((state) => state.setModalToggle)
    const setSelectedOrderNo = useImageStore((state) => state.setSelectedOrderNo)
    const dueDate = format(parse(row.dueDate, SERVER_FORMAT, new Date()), DISPLAY_FORMAT)
   
    const arrayIndexOffset = 1

    const handleImageModal = useCallback((event) => {
        event.stopPropagation();

        setModalToggle(true)
        setSelectedOrderNo(row.orderNo)
    }, [row.orderNo, setModalToggle, setSelectedOrderNo])

    const propData = [
        row.orderNo,
        row.client,
        row?.clientNo || '',
        row.jobDesc,
        row.paid ? <Typography>Paid Order</Typography> : <Typography>Unpaid Order</Typography>,
        <Button onClick={handleImageModal}>
            Images
        </Button>,
        dueDate
    ]

    return (
        <>
            <TableRow 
                selected={open} 
                key={row._id} 
                onClick={() => setOpen(!open)}>
                <TableCell sx={{borderBottom: 0}} id={row.id}>
                    <IconButton
                        align='center'
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                {/* Regular Data Table */}
                {propData.map((curRow, index) => {
                    if (index === propData.length - arrayIndexOffset) {
                        return  (
                            <TableCell sx={{padding: '2px 16px', borderBottom: 0, minWidth: 150}} key={`regTable-${index}`}>
                                    {curRow}
                            </TableCell>
                        )
                    }

                    return <TableCell sx={{borderBottom: 0}} align='left' key={`regTable-${index}`}>
                            {curRow}
                    </TableCell>
                })}
            </TableRow>
            <TableRow key={`${row._id}-subheader`} selected={open}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Stack direction="row" sx={{width: '100%', flexWrap: 'wrap'}} justifyContent="center"> 
                            {row.items.length && row.items.map((item, index) => {
                                return (
                                    <Details.DespatchItems 
                                        key={index}
                                        itemDetails={item}
                                    />
                                )
                            })}

                        </Stack>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default memo(DespatchRow);
