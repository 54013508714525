import { useQuery } from 'react-query';
import { API } from 'api';
import { API_ENDPOINTS } from 'util/constants/api';
import { queryKeys } from 'util/constants/queryKeys';

const options = {
    staleTime: Infinity,
    refetchInterval: 300000,
};

const fetchData = async (endpoint, queryParam = '') => {
    const { data } = await API.get(`${API_ENDPOINTS[endpoint]}${queryParam}`);


    if (data.success && endpoint === 'GET_PARTS_DASHBOARD') {
        const { parts, usedParts } = data;

        const processedPart = parts.map((partData) => {
            const usedPart = usedParts.find((usedPartData) => usedPartData.part === partData.id);

            if (usedPart) {
                return {
                    ...partData,
                    supplier: partData?.supplier || null,
                    amount: partData.amount - usedPart.amount,
                    totalPrice: partData.totalPrice - usedPart.value,
                };
            }

            return { ...partData, supplier: partData?.supplier || null };
        });

        const unusedTotals = parts.reduce(
            (prevValue, currVal, index) => {
                prevValue.totalPrice += currVal.totalPrice;
                prevValue.totalParts += currVal.amount;

                return prevValue;
            },
            { totalPrice: 0, totalParts: 0 }
        );

        const processedUsedParts = usedParts.map((usedPartData) => {
            const partData = parts.find((stockData) => stockData.id === usedPartData.part);

            return {
                ...usedPartData,
                name: partData.name,
                supplier: partData?.supplier || null,
            };
        });

        const usedTotals = usedParts.reduce(
            (prevValue, currVal, index) => {
                prevValue.totalPrice += currVal.value;
                prevValue.totalParts += currVal.amount;

                return prevValue;
            },
            { totalPrice: 0, totalParts: 0 }
        );

        data.parts = processedPart;
        data.usedParts = processedUsedParts;
        data.totals = { unusedTotals, usedTotals, totalStock: unusedTotals.totalParts + usedTotals.totalParts };
    }

    return data;
};

export const useDashboardSales = () => {
    return useQuery('dashboardSales', () => fetchData('GET_DASHBOARD_SALES'), options);
};

export const useDashboardAccounts = () => {
    return useQuery('dashboardAccounts', () => fetchData('GET_DASHBOARD_ACCOUNTS'), options);
};

export const useDashboardProductions = () => {
    return useQuery('dashboardOnTimeProductions', () => fetchData('GET_DASHBOARD_PRODUCTIONS'), options);
};

export const useDashboardCurrentOrders = () => {
    return useQuery('dashboardCurrentOrders', () => fetchData('GET_DASHBOARD_CURR_ORDERS'), options);
};

export const useDashboardParts = () => {
    return useQuery('dashboardParts', () => fetchData('GET_PARTS_DASHBOARD'), options);
};

// Not Legacy
export const useDashboardQuotas = () => {
    return useQuery(queryKeys.dashboardQuotas, () => fetchData('GET_QUOTA'), options);
}