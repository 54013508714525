import ProductDetails from './ProductDetails';
import OrderDetails from './OrderDetails';
import TicketDetails from './TicketDetails';
import DespatchItemDetails from './DespatchItemDetails';
import UmbrellaDetails from './UmbrellaDetails';

const Details = {
    Product: ProductDetails,
    Order: OrderDetails,
    Tickets: TicketDetails,
    DespatchItems: DespatchItemDetails,
    Umbrellas: UmbrellaDetails,
};

export { Details };
