export const currencyFormatter = (amount) => {
    return Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount);
};

export const stringCapitalizer = (string) => {
    if (!string) {
        return '';
    }

    return string[0].toUpperCase() + string.slice(1);
};
