import { Typography } from '@mui/material'
import { useSpring, animated } from '@react-spring/web'

const AnimatedNumber = ({ number, sx, variant = 'h2', unit = '' }) => {
    const props = useSpring({
        from: { number: 0 },
        delay: 400,
        number: number || 0
    })

    return (
        <Typography variant={variant} sx={{fontWeight: 'bold', textAlign: 'center', ...sx}}>
            <animated.div>
                {props.number.to(x => x.toFixed(0))}
            </animated.div>
        </Typography>
    )
}

export default AnimatedNumber