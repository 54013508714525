import { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { StyledPaper } from "components/StyledPaper"
import { Container, Stack, TextField, Typography, Alert, AlertTitle, Box, Link } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { useNavigate, useLocation } from "react-router-dom"
import { API } from "api"
import { API_ENDPOINTS } from "util/constants/api"
import useAuthStore from 'store/auth'
import jwt_decode from 'jwt-decode'
import { format, fromUnixTime } from 'date-fns'
import { SERVER_FORMAT } from 'util/constants/dates'
import { ROLES } from 'util/constants/permissions'
import { acceptPrivacyNotice } from 'util/helpers/apiHelpers'

const Login = () => {
    const authError = useAuthStore((state) => state.authError)
    const isFirstTime = useAuthStore((state) => state.isFirstTime)

    // Setters
    const setExpiryDate = useAuthStore((state) => state.setExpiryDate)
    const setLoggedIn = useAuthStore((state) => state.setLoggedIn)
    const setToken = useAuthStore((state) => state.setToken)
    const setAuthError = useAuthStore((state) => state.setAuthError)
    const setRole = useAuthStore((state) => state.setRole)

    const setFirstTime = useAuthStore((state) => state.setFirstTime)

    const resetAuth = useAuthStore((state) => state.resetAuth)

    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState({show: false, message: ''})

    const location = useLocation()
    const navigate = useNavigate()

    const usernameRef = useRef()
    const passwordRef = useRef()

    const from = location.state?.from?.pathname || '/'

    const _handleLogin = async (event) => {
        setLoading(true)
        setError({...error, show: false})
        event.preventDefault()

        try {
            const response = await API.post(API_ENDPOINTS.LOGIN, { formData: { username: usernameRef.current.value, password: passwordRef.current.value }})
            
            if (response.data.success) {
                const decodedToken = jwt_decode(response.data.jwt)
                const expiry = format(fromUnixTime(decodedToken.exp), SERVER_FORMAT)

                setExpiryDate(expiry)
                setRole(response.data.role)
                setToken(response.data.jwt)
                
                if (response.data.firstLogin) {
                    setFirstTime(true)
                } else {
                    setLoggedIn(true)
                    navigate(from, {replace: true}) 
                }
            }
        } catch(e) {
            console.log(e)
            setError({show: true, message: e.data.message})

        } finally {
            setLoading(false)
        }
    }

    const _handlePrivacyAccept = useCallback(async (event) => {
        setLoading(true)
        setError({...error, show: false})
        event.preventDefault()

        const response = await acceptPrivacyNotice()
        setLoading(false)
        
        if (response.success) {
            setFirstTime(false)
            setLoggedIn(true)
            navigate(from, {replace: true})
        } else {
            setError({show: true, message: response.data.message})
        }
    }, [error, navigate, from, setFirstTime, setLoggedIn])

    const _handleReject = useCallback(() => {
        setLoggedIn(false)
        setExpiryDate('')
        setRole('')
        setToken('')
        
        resetAuth()
    }, [setLoggedIn, setExpiryDate, setRole, setToken, resetAuth])

    const _renderPolicy = useMemo(() => {
        return (
            <Stack direction="column" spacing={6} sx={{padding: 2}}>
                <Typography variant="h5" sx={{textAlign: 'center', fontWeight: 'bold'}}>
                    Privacy Policy
                </Typography>

                <Box>
                    By logging in, you'd be accepting our <Link href={"https://revolvashade.com/privacy-policy-2/"} target="_blank">privacy policy.</Link>
                </Box>

                {error.show && (
                    <Alert severity="error">
                        <AlertTitle sx={{textAlign: 'left'}}>Error</AlertTitle>
                        {error.message}
                    </Alert>
                )}

                <Stack direction="row" alignItems={'center'} justifyContent={"center"} spacing={4}>
                    <LoadingButton type="button" variant="outlined" color="error" onClick={_handleReject} loading={isLoading}>
                        Logout
                    </LoadingButton>

                    <LoadingButton type="button" variant="contained" onClick={_handlePrivacyAccept} loading={isLoading}>
                        Continue
                    </LoadingButton>
                </Stack>
            </Stack>
        )
    }, [_handlePrivacyAccept, _handleReject, error, isLoading])


    useEffect(() => {
        if (authError) {
            setError({show: true, message: authError})
            setAuthError('')
        }
    }, [authError, setAuthError])
    
    return (
        <Container maxWidth="sm" sx={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <StyledPaper flex={1} sx={{width: '100%', padding: '32px', borderLeft: '14px solid #2196f3'}}>
                { !isFirstTime && (
                    <Stack direction="column" spacing={6} sx={{padding: 2}}>
                        <Typography variant="h5" sx={{textAlign: 'center', fontWeight: 'bold'}}>
                            Login
                        </Typography>

                        <form id="loginForm" onSubmit={_handleLogin}>
                            <Stack display="column" spacing={2}>
                                <TextField inputRef={usernameRef} id="username" name="username" label="Username" type="input" placeholder={'Username'} required/>
                                <TextField inputRef={passwordRef} id="password" name="password" label="Password" type="password" placeholder={'Password'} autoComplete="on" required/>
                            </Stack>
                        </form>

                        {error.show && (
                            <Alert severity="error">
                                <AlertTitle sx={{textAlign: 'left'}}>Error</AlertTitle>
                                {error.message}
                            </Alert>
                        )}

                        <LoadingButton type="submit" variant="contained" form="loginForm" loading={isLoading}>
                            Login
                        </LoadingButton>
                    </Stack>
                )}

                { isFirstTime && <>{_renderPolicy}</>}
            </StyledPaper>

        </Container>
    )
}

export default Login