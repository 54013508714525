import { Fragment, useRef } from 'react'

import { 
    Box, 
    IconButton, InputBase, 
} from '@mui/material'
import { StyledPaper } from "components/StyledPaper"
import { debounce } from 'util/helpers/debounce'
import { Search } from '@mui/icons-material'

export const TableSearch = ({onSearch, expansionSlot = [], defaultSearch = ''}) => {
    const searchRef = useRef()
    return (
        <StyledPaper sx={{display: 'flex', flexDirection: 'column', m: '16px 0 32px 0'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Box>
                        <IconButton onClick={() => onSearch(searchRef.current.value)} sx={{p: '10px'}} aria-label="search">
                            <Search />
                        </IconButton>
                    </Box>
                    <Box sx={{width: '100%'}}>
                        <InputBase 
                            inputRef={searchRef}
                            sx={{ml: 1, width: '100%'}}
                            placeholder="Search client..."
                            defaultValue={defaultSearch}
                            inputProps={{'aria-label': 'search client'}}
                            onChange={debounce((event) => onSearch(event.target.value))}
                        />
                    </Box>
                </Box>

                {/* Pass an Array of expansion features (i.e. Filter Chips), to render */}
                {expansionSlot.length ? (expansionSlot.map((module, index) => {
                    if (module) {
                        return <Fragment key={index}>{module}</Fragment>
                    }

                    return <Fragment key={index}></Fragment>
                })): <></>}
            </StyledPaper>
    )
}