import { useState, memo, useMemo, useCallback } from 'react';
import { TableCell, TableRow, IconButton, Collapse, Tooltip, Stack, Box, Button, LinearProgress, Typography, } from '@mui/material'
import { KeyboardArrowUp, KeyboardArrowDown, Error } from '@mui/icons-material';
import { useQueryClient } from 'react-query';
import { Details } from 'components/Details';
import { CommentList } from 'components/CommentsList';
import { ActionModal } from 'components/Modals';
import { getProductData } from 'components/Modals/products.data';
import { API } from 'api';
import { API_ENDPOINTS } from 'util/constants/api';
import { useSnackbar } from 'notistack';
import { COLORS } from '../../../util/constants/commons';
import { PermissionBarrier } from 'components/Permissions';
import { ROLES } from 'util/constants/permissions';
import { format, isAfter, parse } from 'date-fns';
import { DISPLAY_FORMAT, SERVER_FORMAT } from 'util/constants/dates';
import useAuthStore from 'store/auth'
import { usePermission } from 'hooks/permission.hooks';
import { LoadingButton } from '@mui/lab';
import ProgressBar from 'components/Loaders/ProgressBar';
import { TicketComments } from 'components/CommentsList/TicketComments';
import useImageStore from 'store/images'

const DIV_CODE = {
    1: 'REVOLVASHADE',
    2: 'CLEVERSHADE',
    3: 'BLACKSHEEP',
    4: 'TRAX'
}

// const ProgressBox = ({ completedCount, progressLength }) => {
//     const BOX_PADDING = '12px'
//     const BOX_RADIUS = 12
//     const boxData = useMemo(() => {
//         const box = [
//             {padding: BOX_PADDING, borderTopLeftRadius: BOX_RADIUS, borderBottomLeftRadius: BOX_RADIUS, background: COLORS.warning_light},
//         ]

//         for(let i = 1; i < progressLength; i++) {
//             if (i + 1 === progressLength) {
//                 box.push({padding: BOX_PADDING, borderTopRightRadius: BOX_RADIUS, borderBottomRightRadius: BOX_RADIUS, background: COLORS.warning_light})
//             } else {
//                 box.push({padding: BOX_PADDING, background: COLORS.warning_light},)
//             }
//         }


//         return box
//     }, [progressLength])

//     const renderCompletedBoxes = useMemo(() => {
//         return boxData.map((box, index) => {
//             if (index <= completedCount - 1) {
//                 return {
//                     ...box,
//                     background: COLORS.primary
//                 }
//             }

//             return box
//         })


//     }, [completedCount, boxData])

//     return (
//         <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
//             {renderCompletedBoxes.map((box, index) => (
//                 <Box key={index} sx={box}/>
//             ))}
//         </Box>
//     )
// }

const OrderRow = ({row, comments, clientInfo, employeeInfo, ...props}) => {
    const [open, setOpen] = useState(false)
    const { roleSwitch } = usePermission()
    const authRole = useAuthStore((state) => state.role)
    const dueDate = format(parse(row.dueDate, SERVER_FORMAT, new Date()), DISPLAY_FORMAT)
    const dateToday = new Date()

    const setModalToggle = useImageStore((state) => state.setModalToggle)
    const setSelectedOrderNo = useImageStore((state) => state.setSelectedOrderNo)

    const isDateAfter = isAfter(dateToday, new Date(row.dueDate))
    const arrayIndexOffset = 1

    // let PC_FORMS_URL = `/api/PCForms?umbrella=${row._id}&orderNo=${row.orderNo}&index=${row.productIndex}&copy=true`

    // if (row?.frameColour?.code) {
    //     PC_FORMS_URL += `&frameColour=${row.frameColour.code}`
    // }

    const isWholesaler = useMemo(() => authRole === ROLES.wholesaler, [authRole])

    const orderName = useMemo(() => {
        const name = row.cReference || row.customer

        if (isWholesaler) {
            return name
        }

        // return row.customerName

        return row.customer
    }, [row.cReference, row.customer, isWholesaler])

    // const translatedDivCode = useMemo(() => {
    //     if (row.divCode) {
    //         return DIV_CODE[row.divCode]
    //     }

    //     return ''
    // }, [row.divCode])

    const handleImageModal = useCallback((event) => {
        event.stopPropagation();

        setModalToggle(true)
        setSelectedOrderNo(row.orderNo)
    }, [row.orderNo, setModalToggle, setSelectedOrderNo])



    // TODO: rewrite all instances of row data to support ColDef formats
    const propData = [
        `${row.orderNo}`,
        orderName || ' ',
        row?.clientNo || ' ',
        row.jobDescription,
        <Button onClick={handleImageModal}>
            Images
        </Button>,
        <ProgressBar progressPercent={row.progressPercent} dueDate={row.dueDate}/>,
        dueDate
    ]

    return (
        <>
            <TableRow 
                selected={open} 
                key={row.orderNo} 
                onClick={() => setOpen(!open)}>
                <TableCell id={row._id} sx={{padding: '2px', borderBottom: 0}}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                {/* Regular Data Table */}
                {propData.reduce((processedRow, curRow, index) => {
                    if (!curRow) return processedRow

                    if (!isWholesaler && index === propData.length - arrayIndexOffset) {
                        return [...processedRow, (
                            <TableCell sx={{padding: '2px 16px', borderBottom: 0, minWidth: 150}} key={`regTable-${index}`}>
                                <Stack direction="row" spacing={1} alignItems="center">

                                    {curRow}
                                    {isDateAfter && 
                                        <>
                                            <Tooltip title={roleSwitch({
                                                [ROLES.wholesaler]: `This product is past its target date`,
                                                default: `This product is past its due date`
                                            })}>
                                                <Error color={'error'} sx={{marginLeft: '4px' }}/>
                                            </Tooltip>
                                        </>
                                    }
                                </Stack>
                            </TableCell>
                        )]
                    }
                    

                    return [...processedRow, (
                        <TableCell sx={{padding: '2px 16px', borderBottom: 0}} key={`regTable-${index}`}>{curRow}</TableCell>
                    )]
                }, [])}
            </TableRow>
            <TableRow selected={open}>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Stack direction="row">
                            {/* <Details.Product
                                product={row}
                                clientInfo={clientInfo}
                                currPage={props.curPage}
                                customAction={
                                    <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'left'}}>
                                        <Stack direction="row">
                                            <Box padding={1}>
                                                <ActionModal.Product 
                                                    type="modify"
                                                    initialProductDetails={getProductData(row)}
                                                    queryKey={props.queryKey}
                                                    buttonProps={{
                                                        variant: 'contained',
                                                    }}
                                                />
                                            </Box>

                                            <Box padding={1}>
                                                <ActionModal.Order 
                                                    type="modify"
                                                    initialOrderData={row.orderDetails}
                                                    queryKey={props.queryKey}
                                                />
                                            </Box>

                                            

                                            <PermissionBarrier scopes={[ROLES.admin, ROLES.sales]}>

                                                <Box padding={1}>
                                                    <LoadingButton 
                                                        onClick={() => queryClient.invalidateQueries('sales')} 
                                                        target="_blank" 
                                                        href={PC_FORMS_URL} 
                                                        variant="contained" 
                                                    >
                                                        PC Forms
                                                    </LoadingButton> 
                                                </Box>
                                            </PermissionBarrier>
                                        </Stack>
                                        <Stack direction="row">
                                            <PermissionBarrier scopes={[ROLES.admin, ROLES.sales]}>

                                            
                                                <Box padding={1}>
                                                    <ActionModal.Simple 
                                                        modalTitle="Confirm Remove?"
                                                        dialogMsg="Are you sure you want to remove this order?"
                                                        onConfirm={_handleDelete}
                                                        btnText="Remove"
                                                        isLoading={isLoading}
                                                        buttonProps={{
                                                            variant: 'text',
                                                            color: 'error'
                                                        }}
                                                    />
                                                </Box>
                                            </PermissionBarrier>
                                            <PermissionBarrier scopes={[ROLES.admin]}>

                                                <Box padding={1}>
                                                    <ActionModal.Simple 
                                                        modalTitle="Send Back Product?"
                                                        dialogMsg={`Are you sure you would like to move this product back to ${props.curPage === 'despatch' ? 'Orderbook' : 'Sales'} ?`}
                                                        onConfirm={_handleSendBack}
                                                        btnText="Send Back"
                                                        isLoading={isSendBackLoading}
                                                        buttonProps={{
                                                            variant: 'text',
                                                            color: 'error'
                                                        }}
                                                    />
                                                </Box>
                                            </PermissionBarrier>
                                        </Stack>
                                    </Box>
                                }
                            /> */}

                            <Details.Tickets row={row} currPage={props.curPage}/>
                            {/* <CommentList commentInfo={comments} orderNo={row.orderNo} /> */}

                            <TicketComments ticketList={row.tickets} row={row}  />
                        </Stack>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default memo(OrderRow);