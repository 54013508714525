import { useState, useMemo } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, Divider, Stack, TextField, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { API } from 'api'
import { API_ENDPOINTS } from 'util/constants/api'
import { useSnackbar } from 'notistack'
import { useWholesalerSettings } from 'hooks/lookups.hooks'

const FIELDS = {
    wholesalerWeeks: { required: true, label: 'Due Date Weeks', name: 'wholesalerWeeks', type: 'number'},
}

export const Wholesalers = () => {
    const { data: wholesalerLookup, isLoading, refetch } = useWholesalerSettings()
    const [isModifyLoading, setModifyLoading] = useState(false)

    const { enqueueSnackbar } = useSnackbar()

    const { 
        control,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: {
            wholesalerWeeks: "",
        },
        mode: 'onBlur'
    })

    const formRules = useMemo(() => ({required: 'This field is required'}), [])

    const _onModify = async (formData) => {
        setModifyLoading(true)

        const payload = {
            ...formData,
        }

        const { data } = await API.put(API_ENDPOINTS.MODIFY_WHOLESALER_WEEKS, {formData: payload})
        
        if (data?.success) {
            reset()
            refetch()
            enqueueSnackbar(data.message, { variant: 'success'})
        } else {
            enqueueSnackbar(data.message, { variant: 'error'})
        }

        setModifyLoading(false)
    }

    const _renderField = (fieldData) => {
        return (
                <Controller 
                    key={fieldData.name}
                    name={fieldData.name}
                    control={control}
                    defaultValue=""
                    rules={fieldData.required && formRules}
                    render={({ field: {name, value, onChange, onBlur}}) => (
                        <TextField 
                            fullWidth
                            type={fieldData.type}
                            label={fieldData.label} 
                            name={name} 
                            value={value} 
                            onChange={onChange} 
                            onBlur={onBlur}
                            error={!!errors[name]} 
                            helperText={errors[name]?.message}
                        />
                    )}
                />
        )
    }

    return (
        <Box>
            <Typography variant="body2" sx={{textAlign: 'left', color: '#90caf9', padding: '12px 16px'}}>
                WHOLESALERS
            </Typography>
            
            <Divider sx={{borderColor: '#90caf9', borderWidth: '1px'}}/>
            
            <Box sx={{p: 3}}>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h5" sx={{fontWeight: 'bold', textAlign: 'left'}}>
                        Wholesaler Settings
                    </Typography>
                    <Typography sx={{color: 'text.secondary', textAlign: 'left'}}>
                        modify wholesaler settings
                    </Typography>

                    <Stack direction="column" spacing={2}>
                        <Stack direction="column" spacing={2}>
                            <form id="modifyWholesaler" onSubmit={handleSubmit(_onModify)}>
                                {_renderField(FIELDS.wholesalerWeeks)}
                            </form>

                            {!isLoading && (
                                <Typography sx={{textAlign: 'left', fontWeight: '700'}}>
                                    Current set due date weeks: {wholesalerLookup?.settings.wholesalerWeeks}
                                </Typography>
                            )}
                        </Stack>

                        <Box sx={{alignSelf: 'flex-end', pt: 2}}>
                            <LoadingButton type="submit" form="modifyWholesaler" loading={isModifyLoading} disabled={!isValid} variant="contained" >
                                Modify
                            </LoadingButton>
                        </Box>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    )
}