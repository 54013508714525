import { 
    DialogContent, 
    DialogTitle,
    Button, 
    TextField, 
    Stack, 
    Accordion, 
    AccordionSummary, 
    AccordionDetails,
    Typography,
    List,
    DialogActions,
    ListItem,
    ListItemText,
    IconButton,
    Autocomplete,
} from "@mui/material"
import { LoadingButton } from '@mui/lab'
import { AddCircleOutlined, ExpandMore, Close, Delete } from "@mui/icons-material"
import { DatePicker } from "@mui/x-date-pickers"
import { useMemo, useState, useEffect } from 'react'
import { StyledDialog } from 'components/StyledDialog'
import ProductModal from "./Products"
import { PRODUCT_FIELDS, getProductData, getProductName } from "./products.data"
import { useForm, Controller } from "react-hook-form"
import { useQueryClient } from "react-query"
import { useSnackbar } from "notistack"
import { API } from "api"
import { API_ENDPOINTS } from "util/constants/api"
import { debounce } from "util/helpers/debounce"
import { useColours, useFrames, useModelData, useProductData, useWholesalerSettings } from "hooks/lookups.hooks"
import { DUE_DATE_FORMAT, SERVER_FORMAT } from "util/constants/dates"
import { addWeeks, format } from "date-fns"
import useAuthReducer from 'store/auth'
import { ROLES } from "util/constants/permissions"
import { usePermission } from "hooks/permission.hooks"

const DUE_DATE_WEEKS = 4

const OrderModal = ({isOpen, onClose, type, initialOrderData, queryKey = 'sales', ...props}) => {
    const queryClient = useQueryClient()
    // const { data: wholesalerSettings, isLoading: isWholesalerLoading} = useWholesalerSettings()
    // const { data: productsDataLookup } = useProductData()
    const { data: modelsDataLookup, isLoading: isModelsLoading } = useModelData()    
    const { data: framesLookup, isLoading: isFramesLoading } = useFrames()
    const { data: coloursLookup, isLoading: isColoursLoading } = useColours()

    const { enqueueSnackbar } = useSnackbar()
    
    // Product accordion
    const [isExpanded, setExpanded] = useState(false)

    const _productDataList = useMemo(() => {
        if (isModelsLoading || isFramesLoading || isColoursLoading) {
            return {
                models: [],
                frames: [],
                colours: [],
                bases: []
            }
        }

        return {
            models: modelsDataLookup.models,
            frames: framesLookup.frames,
            colours: coloursLookup.colours,
            bases: modelsDataLookup.models.filter((model) => model.displayName.toLowerCase().includes('base'))
        }
    }, [modelsDataLookup, framesLookup, coloursLookup, isModelsLoading, isFramesLoading, isColoursLoading])


    // Form related states
    const _processedUmbrellas = useMemo(() => {
        if (!initialOrderData) {
            return []
        }

        const newUmbrellaList = initialOrderData.umbrellas.map((umbrella, index) => {
            let builder = {}

            for (const dataField of Object.values(PRODUCT_FIELDS)) {

                if (umbrella[dataField.name]) {
                    builder[dataField.name] = _productDataList[dataField.options].find((targetData, index) => targetData.id === umbrella[dataField.name])
                }
            }

            builder.indexId = index
            builder.displayName = getProductName(null, builder.model.code, _productDataList.models)

            return builder
        })

        
        return newUmbrellaList
    }, [initialOrderData, _productDataList])

    const [productList, setProductList] = useState(_processedUmbrellas)
            
    const [cReference, setCReference] = useState(initialOrderData?.reference || '')
    const [isSubmitLoading, setSubmitLoading] = useState(false)

    const _onSubmit = async (newData) => {
        const payload = {
            formData: {
                cReference,
                products: productList.map((product, index) => {
                    let builder = {}

                    Object.keys(product).forEach(key => {
                        builder[key] = product[key]?.code ? {
                            code: product[key].code,
                            id: product[key].id
                        } : product[key]
                    })

                    return builder
                }),
            }
        }

        setSubmitLoading(true)

        if (type === 'modify') {
            payload.formData.id = initialOrderData.id
            payload.pageKey = queryKey
            payload.customer = initialOrderData.customer
            
            const { data } = await API.put(`${API_ENDPOINTS.ORDERS}/${initialOrderData.id}`, payload)
            
            if (data?.success) {
                enqueueSnackbar('Order modified', { variant: 'success'})
                onClose()
                setProductList([])
                queryClient.invalidateQueries(queryKey)
            } else {
                enqueueSnackbar(data.message, { variant: 'error'})
            }
        } else {
            const { data } = await API.post(API_ENDPOINTS.ORDERS, payload)
            if (data?.success) {
                enqueueSnackbar('Order created', { variant: 'success'})
                onClose()
                setProductList([])
                queryClient.invalidateQueries('sales')
            } else {
                enqueueSnackbar(data.message, { variant: 'error'})
            }
        }

        setSubmitLoading(false)
    }

    const _handleDelete = (product) => {
        // Filter out product to remove
        const newProductList = productList.length === 1 ? [] : productList.filter(item => item.indexId !== product.indexId)
        
        setProductList(newProductList)
    }

    const _handleAddProduct = (data) => {
        // Create an indexID to track added product within the UI...
        // Array.length adds an offset of 1 if there's an item
        // This way we can still have 0 index if the list is empty and auto increment
        data.indexId = productList.length

        setProductList([...productList, data])
    }

    const _handleModify = (modifiedProduct) => {
        setProductList(productList.map(item => {
            if (modifiedProduct.indexId === item.indexId) {
                return modifiedProduct
            }

            return item
        }))
    }
    

    const _renderProductList = (product, index) => {
        let productData = product

        return (
            <ListItem 
                key={index}
                sx={{pr: '96px'}}
                secondaryAction={
                    <Stack direction='row' spacing={0}>
                        <ProductModal 
                            type="modify"
                            initialProductDetails={productData}
                            onProductSubmit={_handleModify}
                            isIconButton
                            buttonProps={{
                                sx: {
                                    marginRight: 1
                                }
                            }}
                        />
                        
                        { queryKey === 'sales' && (
                            <IconButton edge="end" color="error" onClick={() => _handleDelete(productData)}>
                                <Delete />
                            </IconButton>
                        ) }
                    </Stack>
                }>
                
                <ListItemText primary={productData.displayName} />
            </ListItem>
        )
    }

    return (
      
            <StyledDialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle sx={{m: 0, pt: 2, alignItems: 'center', display: 'flex', textTransform: 'capitalize'}}>
                    {`${type} Orders`}
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            marginLeft: 'auto',
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <Stack direction="column" spacing={2}>
                        <TextField 
                            id='customerReference'
                            variant="outlined"
                            label="Customer Reference"
                            value={cReference}
                            fullWidth
                            minRows={4}
                            multiline
                            onChange={(event) => setCReference(event.target.value)}
                        />
                    </Stack>

                    <Accordion 
                        sx={{mt: 2}} 
                        expanded={isExpanded} 
                        onChange={(event) => {
                            event.preventDefault(); 
                            event.stopPropagation();
                            setExpanded(!isExpanded)
                        }}>
                        <AccordionSummary 
                            expandIcon={<ExpandMore />} 
                            sx={{
                                '& .MuiAccordionSummary-content': {
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }
                            }}
                            aria-controls="product-panel-content" 
                        >
                            <Typography>
                                View Products {productList.length > 0 && `(${productList.length})`}
                            </Typography>

                            {queryKey === 'sales' && 
                                <ProductModal 
                                    type="add" 
                                    onProductSubmit={_handleAddProduct}
                                    buttonProps={{
                                        variant: 'text',
                                        startIcon: <AddCircleOutlined />,
                                        size: 'small',
                                        sx: {mr: 2}
                                    }} />
                            }
                        </AccordionSummary>

                        <AccordionDetails>
                            <List dense sx={{width: '100%'}}>
                                {/* Product Data here */}
                                {!productList.length ? (
                                    <ListItem>
                                        <ListItemText primary={'You have yet to add products on this order...'} />
                                    </ListItem>
                                ) : productList.map(_renderProductList)}
                            </List>
                        </AccordionDetails>
                    </Accordion>
                </DialogContent>

                <DialogActions>
                    <Button 
                        variant="text"
                        sx={{marginRight: 'auto'}} 
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton 
                        variant="contained" 
                        loading={isSubmitLoading}
                        disabled={productList.length === 0 } 
                        onClick={_onSubmit}>
                        {`${type} Order`}
                    </LoadingButton>
                </DialogActions>
            </StyledDialog>

    )
}

export default OrderModal