import { Box, Stack, Typography } from "@mui/material"
import QuotaProgress from "components/DashboardSections/QuotaProgress"

const Dashboard = () => {
    return (
        <Box sx={{marginTop: 4}}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom sx={{mb: 4}}>
               Dashboard
            </Typography>

            <Stack flex={1} alignItems={'center'} justifyContent={'center'} spacing={2}>
                <QuotaProgress />
            </Stack>
		</Box>
    )
}

export default Dashboard