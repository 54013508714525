import {
    Collapse,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import {
    ColumnDef,
    ExpandedState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getSortedRowModel,
    Row,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';
import { Fragment, useCallback, useState } from 'react';

export type CustomColumnDef<TData> = ColumnDef<TData> & {
    hasCustomRender?: boolean;
    isIconButton?: boolean;
};

interface OTableProps<TData> {
    data: TData[];
    columns: CustomColumnDef<TData>[];
    renderSubComponent: (props: { row: Row<TData> }) => React.ReactElement;
    getRowCanExpand: (row: Row<TData>) => boolean;
    mountedSubComponent?: boolean;
}

function OTable2<TData>({
    data = [],
    columns,
    mountedSubComponent = false,
    renderSubComponent,
    getRowCanExpand,
}: OTableProps<TData>) {
    const [expanded, setExpanded] = useState<ExpandedState>({});
    const [sorting, setSorting] = useState<SortingState>([
        {
            id: 'dueDate',
            desc: false,
        },
    ]);

    const tableFunctions = useReactTable<TData>({
        data,
        columns,
        state: {
            expanded,
            sorting,
        },
        onExpandedChange: setExpanded,
        onSortingChange: setSorting,
        getRowCanExpand,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const getOrderBy = useCallback(
        (headerId) => {
            if (headerId === sorting[0].id) {
                return sorting[0].desc ? 'desc' : 'asc';
            }

            return 'asc';
        },
        [sorting]
    );

    const handleSort = useCallback(
        (headerId) => {
            setSorting([
                {
                    id: headerId,
                    desc: headerId === sorting[0].id ? !sorting[0].desc : true,
                },
            ]);
        },
        [sorting, setSorting]
    );

    return (
        <TableContainer component={Paper}>
            <Table size="small" sx={{ overflow: 'hidden' }}>
                <TableHead>
                    {/* Column Groups */}
                    {tableFunctions.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                const columnDef = header.column.columnDef as CustomColumnDef<TData>;

                                if (columnDef.isIconButton) {
                                    return (
                                        <TableCell align="left" key={header.id} colSpan={header.colSpan}>
                                            {flexRender(columnDef.header, header.getContext())}
                                        </TableCell>
                                    );
                                }

                                return (
                                    <TableCell
                                        align="left"
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        sortDirection={getOrderBy(header.id)}
                                    >
                                        {header.isPlaceholder && null}

                                        {!header.isPlaceholder && (
                                            <TableSortLabel
                                                active={sorting[0].id === header.id}
                                                direction={getOrderBy(header.id)}
                                                onClick={() => handleSort(header.id)}
                                            >
                                                {flexRender(columnDef.header, header.getContext())}
                                            </TableSortLabel>
                                        )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableHead>

                <TableBody>
                    {tableFunctions.getRowModel().rows.map((row) => {
                        return (
                            <Fragment key={row.id}>
                                <TableRow
                                    selected={row.getIsExpanded()}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setExpanded({
                                            ...(expanded as object),
                                            [row.id]: !row.getIsExpanded(),
                                        });
                                    }}
                                >
                                    {row.getVisibleCells().map((cell) => {
                                        const columnDef = cell.column.columnDef as CustomColumnDef<TData>;

                                        if (columnDef.isIconButton) {
                                            <TableCell key={cell.id} sx={{ padding: '2px', borderBottom: 0 }}>
                                                {flexRender(columnDef.cell, cell.getContext())}
                                            </TableCell>;
                                        }

                                        return (
                                            <TableCell key={cell.id} sx={{ padding: '2px 16px', borderBottom: 0 }}>
                                                {flexRender(columnDef.cell, cell.getContext())}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>

                                {/* // Sub Component  */}
                                <TableRow selected={row.getIsExpanded()}>
                                    <TableCell sx={{ paddingY: 0 }} colSpan={row.getVisibleCells().length}>
                                        <Collapse in={row.getIsExpanded()} timeout="auto" unmountOnExit={!mountedSubComponent}>
                                            {/* Pass rowData to custom renderer */}
                                            {renderSubComponent({ row })}
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </Fragment>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default OTable2;
