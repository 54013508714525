import { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { TextField, Typography, Box, Stack, Divider, Autocomplete, MenuItem, Grid, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { useModelData } from 'hooks/lookups.hooks'
import { useForm, Controller} from 'react-hook-form'
import { ProductTypeOptions } from 'components/Modals/products.data'
import { COMPANY_OPTIONS, MODEL_FIELDS } from './product-settings.data'
import { API } from 'api'
import { API_ENDPOINTS } from 'util/constants/api'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import { ExpandMore } from '@mui/icons-material'

export const Models = () => {
    const { data: modelLookupData, refetch: refetchLookup } = useModelData()
    const { enqueueSnackbar } = useSnackbar()

    const [selectedModel, setModel] = useState(null)
    const [isCreateLoading, setCreateLoading] = useState(false)
    const [isModifyLoading, setModifyLoading] = useState(false)

    const [createActions, setCreateActions] = useState({
        sewed: false,
        cut: false,
        assembled: false,
        packaged: false,
        pc: false
    })

    const [modActions, setModActions] = useState({
        sewed: false,
        cut: false,
        assembled: false,
        packaged: false,
        pc: false
    })

    const { 
        control: createControl,
        handleSubmit: handleCreateSubmit,
        watch: watchCreate,
        formState: { errors: createErrors, isValid: isCreateValid },
        reset: resetCreate
    } = useForm({
        defaultValues: {
            modelCode: '',
            modelName: '',
            productType: '',
            weight: '',
            price: null,
            xeroID: '',
            size: '',
            company: ''
        },
        mode: 'onBlur'
    })

    const {
        control: modifyControl,
        handleSubmit: handleModifySubmit,
        formState: { errors: modifyErrors, isValid: isModifyValid },
        resetField,
        setValue,
        watch: watchModify,
        reset: resetModify
    } = useForm({
        defaultValues: {
            modelCode: '',
            modelName: '',
            productType: '',
            weight: '', 
            price: null,
            xeroID: '',
            size: '',
            company: ''
        },
        mode: 'onBlur'
    })

    const watchedCreateProductType = watchCreate('productType')
    const watchedModifyProductType = watchModify('productType')

    const formRules = useMemo(() => ({required: 'This field is required'}), [])
    const decimalRules = useMemo(() => (
        { 
            pattern: { 
                value: /^\d*\.?\d*$/, 
                message: 'Not a valid number'
            } 
        }
    ), [])
    
    const _handleModelChange = (event, param) => {
        setModel(param)
    }

    const _onSubmitCreate = async (newData) => {
    // API here...
        const payload = {
            code: newData.modelCode,
            displayName: newData.modelName,
            product: newData.productType,
        }

        const createKeys = Object.keys(createActions)
        let actions = []
        createKeys.forEach(key => {
            if (createActions[key]) {
                actions.push(key.toUpperCase())
            }
        })

        payload.actions = actions

        if (payload.product.toLowerCase() === 'revolvashade' || payload.product.toLowerCase() === 'clevershade' ) {
            payload.company = newData.productType
        } else {
            payload.company = newData.company
        }

        setCreateLoading(true)

        const { data } = await API.post(API_ENDPOINTS.CREATE_MODEL, { formData: payload })

        if (data.success) {
            enqueueSnackbar(data.message, { variant: 'success'})
            refetchLookup()
            resetCreate()
        } else {
            enqueueSnackbar(data.message, { variant: 'error' })
        }

        setCreateLoading(false)
    }

    const _onSubmitModify = async (newData) => {
        const payload = {
            code: newData.modelCode,
            displayName: newData.modelName,
            product: newData.productType,
            id: selectedModel.id
        }

        const modKeys = Object.keys(modActions)
        let actions = []
        modKeys.forEach(key => {
            if (modActions[key]) {
                actions.push(key.toUpperCase())
            }
        })

        payload.actions = actions

        if (payload.product.toLowerCase() === 'revolvashade' || payload.product.toLowerCase() === 'clevershade' ) {
            payload.company = newData.productType
        } else {
            payload.company = newData.company
        }

        setModifyLoading(true)

        const { data } = await API.put(`${API_ENDPOINTS.MODIFY_MODEL}/${payload.id}`, { formData: payload })

        if (data.success) {
            enqueueSnackbar('Successfully modified model', { variant: 'success'})
            refetchLookup()
            resetModify()
            setModel(null)
        } else {
            enqueueSnackbar('Failed to modify model', { variant: 'error' })
        }

        setModifyLoading(false)
    }

    useEffect(() => {
        if (selectedModel) {
            setValue('modelCode', selectedModel?.code, { shouldValidate: true, shouldDirty: true})
            setValue('modelName', selectedModel?.displayName, { shouldValidate: true, shouldDirty: true})
            setValue('company', selectedModel?.company, { shouldValidate: true, shouldDirty: true})

        } else {
            resetField('modelCode')
            resetField('modelName')
            resetField('company')
        }
    }, [selectedModel, resetField, setValue])

    const _renderSelectRange = useCallback(({ field, formState: { errors } }) => {
        const options = field.name === 'productType' ? ProductTypeOptions : COMPANY_OPTIONS


        return (
            <TextField 
                {...field} 
                sx={{textAlign: 'left'}}
                defaultValue="" 
                select 
                fullWidth 
                label={field.name === 'productType' ? 'Product Type' : 'Company'}
                error={!!errors[field.name]} 
                helperText={errors[field.name]?.message}>
                {options.map((option, index) => (
                    <MenuItem key={index} value={option.displayName}>
                        {option.displayName}
                    </MenuItem>
                ))}
            </TextField>
        )
    }, [])

    const _renderControlledField = useCallback((fieldName, controller, errors) => {
        return (
            <Controller 
                name={MODEL_FIELDS[fieldName].name}
                control={controller}
                rules={fieldName === 'price' ? {...formRules, ...decimalRules} : formRules}
                render={({field: {name, value, onChange, onBlur}}) => 
                    <TextField 
                        name={name}
                        value={value || ''} 
                        onChange={(event) => {
                            onChange(event.target.value)
                        }} 
                        onBlur={onBlur} 
                        label={MODEL_FIELDS[fieldName].label}
                        type={'text'}
                        fullWidth
                        error={!!errors[fieldName]} 
                        helperText={errors[fieldName]?.message} 
                    />
                }
            />
        )
    }, [formRules, decimalRules])

    return (
        <Box>
            <Stack direction="column" spacing={1}> 
                <Typography variant="h5" sx={{fontWeight: 'bold', textAlign: 'left'}}>
                    Create Model
                </Typography>
                <Typography sx={{color: 'text.secondary', textAlign: 'left'}}>
                    Create and define a new model
                </Typography>

                <Stack direction="column" spacing={2}>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={6} container spacing={2}>
                                <Grid item xs={12}>
                                   {_renderControlledField('modelCode', createControl, createErrors)}
                                </Grid>

                                <Grid item xs={12}>
                                    {_renderControlledField('modelName', createControl, createErrors)}
                                </Grid>
                            </Grid>

                            <Grid item container xs={6} spacing={2}>
                                <Grid item xs={12}>
                                    <Controller 
                                        name="company"
                                        defaultValue={''}
                                        control={createControl}
                                        rules={{required: (watchedCreateProductType && 
                                            (watchedCreateProductType.toLowerCase() !== 'revolvashade' 
                                            && watchedCreateProductType.toLowerCase() !== 'clevershade')) }}
                                        render={_renderSelectRange}
                                    />
                                </Grid>
                            </Grid>

                            
                        </Grid>
                    </Box>

                </Stack>

                <Box sx={{alignSelf: 'flex-end', pt: 2}}>
                    <LoadingButton loading={isCreateLoading} disabled={!isCreateValid} variant="contained" onClick={handleCreateSubmit(_onSubmitCreate)}>
                        Create
                    </LoadingButton>
                </Box>
            </Stack>

            <Divider sx={{mt: 4, mb: 4}}/>

            <Stack direction="column" spacing={2}> 
                <Typography variant="h5" sx={{fontWeight: 'bold', textAlign: 'left'}}>
                    Modify Model
                </Typography>
                <Typography sx={{color: 'text.secondary', textAlign: 'left'}}>
                    Modify an existing model
                </Typography>
                
                {/* Need to loop through all models to have them be available as one */}
                {/* Modify Model will allow user to change a model's product type */}
                <Autocomplete 
                    name={'Model'}
                    value={selectedModel}
                    onChange={_handleModelChange}
                    clearOnBlur
                    handleHomeEndKeys
                    options={modelLookupData?.models || []}
                    groupBy={(option) => option?.range}
                    getOptionLabel={(option) => option.displayName}
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    renderOption={(props, option) => <li {...props} key={option._id}>{option.displayName}</li>}
                    renderInput={(params) => <TextField {...params} label={'Model'} />}
                />
                
                <Stack direction="column" spacing={2}>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={6} container spacing={2}>
                                <Grid item xs={12}>
                                   {_renderControlledField('modelCode', modifyControl, modifyErrors)}
                                </Grid>

                                <Grid item xs={12}>
                                    {_renderControlledField('modelName', modifyControl, modifyErrors)}
                                </Grid>
                            </Grid>

                            <Grid item container xs={6} spacing={2}>
                                <Grid item xs={12}>
                                    <Controller 
                                        name="company"
                                        defaultValue={''}
                                        control={modifyControl}
                                        rules={{formRules}}
                                        render={_renderSelectRange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                </Stack>

                <Box sx={{alignSelf: 'flex-end', pt: 2}}>
                    <LoadingButton loading={isModifyLoading} disabled={!isModifyValid} variant="contained" onClick={handleModifySubmit(_onSubmitModify)}>
                        Modify
                    </LoadingButton>
                </Box>
            </Stack>
        </Box>
    )
}