import {useState, useEffect} from 'react';
import {
    Button, DialogContent, DialogActions, DialogTitle,
    TextField , Box, IconButton, Autocomplete, Stack
} from "@mui/material"
import { Edit } from '@mui/icons-material'
import { useForm, Controller } from 'react-hook-form'
import { StyledDialog } from 'components/StyledDialog';
import { usePartsData } from 'hooks/lookups.hooks';

const FIELDS = {
    amount: { label: 'Amount', name: 'amount', required: true},
}

export const PartsEntry = ({ onSubmit, type = "add", entryData }) => {
    const [open, setOpen] = useState(false);
    const { data: partsLookupData } = usePartsData()
    const [selectedPart, setSelectedPart] = useState(null)

    const { 
        control,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        reset
    } = useForm({
        defaultValues: {
            amount: "",
        },
        mode: 'onBlur'
    })

    const formRules = {required: 'This field is required'}

    const _handleFormSubmit = (formData) => {
        if (type === 'edit') {
            formData.indexId = entryData.indexId
        }
        onSubmit({...selectedPart, ...formData})
        setSelectedPart(null)
        setOpen(false)

        if (type !== 'edit') {
            reset()
        }
    }

    useEffect(() => {
        if (entryData) {
            setValue('amount', entryData?.amount, { shouldDirty: true, shouldValidate: true })
            setSelectedPart(entryData)
        }
    }, [entryData, setValue])


    const _handlePartChange = (event, param) => {
        setSelectedPart(param)
    }
    

    return (
        <>
            { type === 'add' ? 
                <Button variant="outlined" onClick={() => setOpen(true)}>Add Entry</Button> 
                : 

                <IconButton variant="contained" color="primary" onClick={() => setOpen(true)}>
                    <Edit />
                </IconButton>
            }
            <StyledDialog
                open={open}
                maxWidth="sm"
                fullWidth
                onClose={() => setOpen(false)}>
                <DialogTitle>Parts Form Data</DialogTitle>
                <DialogContent>
                    <Box>
                        <Stack direction="column" spacing={2}>
                            <Autocomplete 
                                name={'parts'}
                                value={selectedPart}
                                onChange={_handlePartChange}
                                clearOnBlur
                                handleHomeEndKeys
                                options={partsLookupData?.parts || []}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                renderOption={(props, option) => <li {...props} key={option._id}>{option.name}</li>}
                                renderInput={(params) => <TextField {...params} label={'Parts'} />}
                            />
                            <form id="formEntry" onSubmit={handleSubmit(_handleFormSubmit)}>
                                {Object.keys(FIELDS).map((key) => {
                                    return (
                                        <Controller 
                                            key={key}
                                            name={FIELDS[key].name}
                                            control={control}
                                            rules={FIELDS[key]?.required && formRules}
                                            defaultValue=""
                                            render={({ field: {name, value, onChange, onBlur}}) => (
                                                <TextField 
                                                    fullWidth
                                                    label={FIELDS[key].label} 
                                                    name={name} 
                                                    value={value} 
                                                    onChange={onChange} 
                                                    onBlur={onBlur}
                                                    error={!!errors[name]}
                                                    helperText={errors[name]?.message}
                                                />
                                            )}
                                        />
                                    )
                                })}
                            </form>
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button
                        type="submit" 
                        form="formEntry" 
                        disabled={!isValid}
                        variant="contained">
                        {type === 'add' ? 'Add' : 'Modify'}
                    </Button>
                </DialogActions>
            </StyledDialog>
        </>
    )
}