import { useState, useCallback, useMemo, useRef } from 'react'

import { 
    Typography, 
    Paper, 
    Table, 
    TableBody, 
    TableContainer, 
    TableHead, 
    TableCell, 
    TableRow, 
    TableSortLabel, 
    TablePagination,
    Box, 
    IconButton, InputBase, Stack, Chip,
    TableFooter
} from '@mui/material'
import { StyledPaper } from "components/StyledPaper"
import { debounce } from 'util/helpers/debounce'
import { TablePaginationActions } from './TablePaginationActions'
import { Search } from '@mui/icons-material'
import { TableSearch } from './TableSearch'


const OTable = ({
    headCells, 
    rows = [], 
    commentInfo, 
    sortOrder = 'asc', 
    sortOrderBy = 'dueDate', 
    renderRow, 
    paginate, 
    paginationProps, 
    rowPerPage = 100, 
    filterText = '',
    ...props}) => {
    const [order, setOrder] = useState(sortOrder)
    const [orderBy, setOrderBy] = useState(sortOrderBy)

    const _handleSort = useCallback((headerID) => {
        const isAsc = orderBy === headerID && order === 'asc'

        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(headerID)
    }, [orderBy, order])

    const _descendingComparator = useCallback((a, b) => {
        let elementA = a[orderBy] === undefined ? '' : a[orderBy]
        let elementB = b[orderBy] === undefined ? '' : b[orderBy]

        // Band-aid fix, requires rewriting table system to implement columns with formatters
        // this fix affects all tables with dates on them that utilizes OTable
        if (orderBy === 'dueDate' || orderBy === 'finishedOn') {
            elementA = elementA === '' ? '' : new Date(elementA)
            elementB = elementB === '' ? '' : new Date(elementB)
        }
    
        if (elementB < elementA) {
            return -1
        }

        if (elementB > elementA) {
            return 1
        }

        return 0
    }, [orderBy])

    const _getComparator = useCallback(() => {
        return order === 'desc'
        ? (a, b) => _descendingComparator(a, b)
        : (a, b) => -_descendingComparator(a, b)
    }, [order, _descendingComparator])

    const _renderChildElement = useCallback((rowData, index) => {
        let curComments = []
        if (typeof (commentInfo) !== 'undefined') {
            curComments = (commentInfo.find(comment => comment.orderNo === rowData.orderNo) || {}).comments
        }

        return renderRow({
            key: `customRow-${rowData.id || rowData.orderNo}`,
            row: rowData,
            comments: curComments,
            ...props
        })
    }, [commentInfo, props, renderRow])

    
    // const _handleSearch = useCallback((text) => {
    //     if (serverSearch) {
    //         searchProps.handleSearch(text)
    //     } else {
    //         setFilterText(text ? text.toLowerCase() : '')
    //     }
    // }, [])

    const _getFilteredSearch = useCallback((rowItem, index) => {
        if (!filterText) return rowItem

        if (props.curPage === 'orderBook') {
            if (rowItem.customer.toLowerCase().includes(filterText.toLowerCase())) {
                return rowItem
            }
                
        } else {
            const customerName = rowItem?.customerName || rowItem?.customer || rowItem?.client

            if (customerName.toLowerCase().includes(filterText.toLowerCase())) {
                return rowItem
            }
        }
        
    }, [filterText, props.curPage])

    const processedRows = useMemo(() => {
        return rows
        .filter(_getFilteredSearch)
        .sort(_getComparator())
    }, [rows, _getFilteredSearch, _getComparator])


    return (
        <>
            {/* <TableSearch  
                onSearch={_handleSearch}
                expansionSlot={[
                    props?.filterList && (
                        <Stack direction="row" alignItems={'center'} sx={{mt: 2}} gap={1}>
                            <Typography>Filters: </Typography>
                            {props.filterList.map((filterItem) => (
                                <Chip key={filterItem.value} 
                                    variant={props.selectedFilter === filterItem.id ? 'filled' : 'outlined'} 
                                    label={filterItem.value} 
                                    color="primary"
                                    size="medium"
                                    onClick={() => props.onFilter(filterItem)}
                                />
                            ))}
                        </Stack>
                    )
                ]}
            /> */}

            <TableContainer component={Paper}>
                <Table size="small" sx={{overflow: 'hidden'}}>
                    <TableHead>
                        {paginate && (
                            <TableRow>
                                {/* Server Side Pagination Implementation */}
                                <TablePagination rowsPerPageOptions={[25, 50, 100]}
                                    count={paginationProps.totalCount}
                                    rowsPerPage={paginationProps.rowsPerPage}
                                    page={paginationProps.page}
                                   
                                    onPageChange={paginationProps.handlePageChange}
                                    onRowsPerPageChange={paginationProps.handleRowsPerPageChange}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        )}
                        <TableRow padding="checkbox">
                            {/* empty header for the arrow icon */}
                            {!props.unClickable ? (<TableCell />) : <></> }
                            {headCells.map((headCell) => {
                                if (headCell.hide) {
                                    return null
                                }

                                return (
                                    <TableCell
                                        align="left"
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={() => _handleSort(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                )

                            })}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            processedRows.map(_renderChildElement)
                        }
                    </TableBody>

                    <TableFooter>
                        {paginate && (
                            <TableRow>
                                {/* Server Side Pagination Implementation */}
                                <TablePagination rowsPerPageOptions={[25, 50, 100]}
                                    count={paginationProps.totalCount}
                                    rowsPerPage={paginationProps.rowsPerPage}
                                    page={paginationProps.page}
                                    slotProps={{
                                        select: {
                                            inputProps: {
                                            'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        },
                                    }}
                                    onPageChange={paginationProps.handlePageChange}
                                    onRowsPerPageChange={paginationProps.handleRowsPerPageChange}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        )}
                    </TableFooter>
                </Table>
            </TableContainer>

            {/* {paginate ? (
                <Box sx={{mt: 4, display: 'flex', justifyContent: 'center'}}>
                    <Pagination count={pageCount} page={page}  size='large' color="primary" onChange={_handlePaginate}/>
                </Box>
            ): null} */}
        </>
    )
}

export default OTable