import { useEffect, useState } from 'react'
import { TextField, Typography, Box, Stack, Divider, Autocomplete, MenuItem } from '@mui/material'
import { useProductData } from 'hooks/lookups.hooks'
import { useForm, Controller} from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { API } from 'api'
import { API_ENDPOINTS } from 'util/constants/api'
import { useSnackbar } from 'notistack'
import { useAnnounceData } from 'hooks/app.hooks'
import { stringCapitalizer } from 'util/helpers/formatter'

const TypeOptions = ['Info', 'Warning', 'Error', 'Success']

export const Announcements = () => {
    const { data: announceData, isLoading: isAPILoading, refetch: refetchLookup} = useAnnounceData()

    const { enqueueSnackbar } = useSnackbar()
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(null)
    const [isCreateLoading, setCreateLoading] = useState(false)
    const [isModifyLoading, setModifyLoading] = useState(false)
    const [isDeleteLoading, setDeleteLoading] = useState(false)

    const { 
        control: createControl,
        handleSubmit: handleCreateSubmit,
        formState: { errors: createErrors, isValid: isCreateValid },
        reset: resetCreate
    } = useForm({
        defaultValues: {
            message: '',
            type: '',
        },
        mode: 'onBlur'
    })

    const {
        control: modifyControl,
        handleSubmit: handleModifySubmit,
        formState: { errors: modifyErrors, isValid: isModifyValid },
        resetField,
        setValue,
        reset: resetModify
    } = useForm({
        defaultValues: {
            message: '',
            type: ''
        },
        mode: 'onBlur'
    })

    const formRules = {required: 'This field is required'}

    const _handleAnnounceChange = (event, param) => {
        setSelectedAnnouncement(param)
    }

    const _onSubmitCreate = async (newData) => {
        // API here...
        setCreateLoading(true)

        const payload = {
            ...newData,
            type: newData.type.toLowerCase()
        }

        const { data } = await API.post(API_ENDPOINTS.POST_ANNOUNCE, { formData: payload })

        if (data.success) {
            refetchLookup()
            enqueueSnackbar(data.message, { variant: 'success' })
            resetCreate()
        } else {
            enqueueSnackbar(data.message, { variant: 'error' })
        }

        setCreateLoading(false)
    }

    const _onSubmitModify = async (newData) => {
        setModifyLoading(true)
      
        const payload = {
            message: newData.message,
            type: newData.type.toLowerCase()
        }


        const { data } = await API.put(`${API_ENDPOINTS.POST_ANNOUNCE}/${selectedAnnouncement.id}`, { formData: payload })

        if (data.success) {
            refetchLookup()
            enqueueSnackbar('Successfully modified announcement', { variant: 'success' })
            resetModify()
            setSelectedAnnouncement(null)
        } else {
            enqueueSnackbar('Unable to modify announcement', { variant: 'error' })
        }

        setModifyLoading(false)
    }

    const _onDelete = async () => {
        setDeleteLoading(true)

        const { data } = await API.delete(`${API_ENDPOINTS.POST_ANNOUNCE}/${selectedAnnouncement.id}`)

        if (data.success) {
            refetchLookup()
            enqueueSnackbar('Successfully deleted announcement', { variant: 'success' })
            resetModify()
            setSelectedAnnouncement(null)
        } else {
            enqueueSnackbar('Unable to modify announcement', { variant: 'error' })
        }

        setDeleteLoading(false)
    }

    useEffect(() => {
        if (selectedAnnouncement) {
            setValue('message', selectedAnnouncement?.message, { shouldValidate: true, shouldDirty: true})
            setValue('type', stringCapitalizer(selectedAnnouncement?.type), { shouldValidate: true, shouldDirty: true})
        } else {
            resetField('message')
            resetField('type')
        }
    }, [selectedAnnouncement, resetField, setValue])


    return (
        <Box>
            <Typography variant="body2" sx={{textAlign: 'left', color: '#90caf9', padding: '12px 16px'}}>
                ANNOUNCEMENTS
            </Typography>
            
            <Divider sx={{borderColor: '#90caf9', borderWidth: '1px'}}/>

            <Box sx={{p: 3}}>
                <Stack direction="column" spacing={1}> 
                    <Typography variant="h5" sx={{fontWeight: 'bold', textAlign: 'left'}}>
                        Create Announcements
                    </Typography>
                    <Typography sx={{color: 'text.secondary', textAlign: 'left'}}>
                        Create new announcements
                    </Typography>

                    <Stack direction="column" spacing={2}>
                        <Controller 
                                name="type"
                                control={createControl}
                                rules={formRules}
                                render={({
                                    field: {name, value, onChange, onBlur}
                                }) => (
                                    <TextField
                                        name={name} 
                                        value={value || ''} 
                                        onChange={onChange} 
                                        onBlur={onBlur}
                                        sx={{textAlign: 'left'}}
                                        fullWidth
                                        select
                                        label="Type"
                                        error={!!createErrors[name]}
                                        helperText={createErrors[name]?.message}
                                    >
                                        {TypeOptions.map((option, index) => (
                                            <MenuItem key={index} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />  
                        
                        <Controller 
                            name="message"
                            control={createControl}
                            rules={formRules}
                            render={({field}) => 
                                <TextField
                                    {...field}
                                    label={'Message'}
                                    error={!!createErrors[field.name]} 
                                    helperText={createErrors[field.name]?.message} 
                                />
                            }
                        />
                    </Stack>

                    <Box sx={{alignSelf: 'flex-end', pt: 2}}>
                        <LoadingButton loading={isCreateLoading} disabled={!isCreateValid} variant="contained" onClick={handleCreateSubmit(_onSubmitCreate)}>
                            Create
                        </LoadingButton>
                    </Box>
                </Stack>

                <Divider sx={{mt: 4, mb: 4}}/>

                <Stack direction="column" spacing={1}> 
                    <Typography variant="h5" sx={{fontWeight: 'bold', textAlign: 'left'}}>
                        Modify Announcement
                    </Typography>
                    <Typography sx={{color: 'text.secondary', textAlign: 'left'}}>
                        Modify an existing announcement
                    </Typography>

                    <Stack direction="column" spacing={2}>
                        <Autocomplete 
                            name={'announcements'}
                            value={selectedAnnouncement}
                            onChange={_handleAnnounceChange}
                            clearOnBlur
                            handleHomeEndKeys
                            sx={{mb: 4}}
                            options={announceData?.announcements || []}
                            getOptionLabel={(option) => option.message}
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            renderOption={(props, option) => <li {...props} key={option._id}>{option.message}</li>}
                            renderInput={(params) => <TextField {...params} label={'Announcements'} />}
                        />

                        <Controller 
                                name="type"
                                control={modifyControl}
                                rules={formRules}
                                render={({
                                    field: {name, value, onChange, onBlur}
                                }) => (
                                    <TextField
                                        name={name} 
                                        value={value || ''} 
                                        onChange={onChange} 
                                        onBlur={onBlur}
                                        sx={{textAlign: 'left'}}
                                        fullWidth
                                        select
                                        label="Type"
                                        error={!!modifyErrors[name]}
                                        helperText={modifyErrors[name]?.message}
                                    >
                                        {TypeOptions.map((option, index) => (
                                            <MenuItem key={index} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />  
                        
                        <Controller 
                            name="message"
                            control={modifyControl}
                            rules={formRules}
                            render={({field}) => 
                                <TextField
                                    {...field}
                                    label={'Message'}
                                    error={!!modifyErrors[field.name]} 
                                    helperText={modifyErrors[field.name]?.message} 
                                />
                            }
                        />
                    </Stack>

                    <Box sx={{alignSelf: 'flex-end', pt: 2}}>
                        <LoadingButton variant="contained" sx={{mr: 2}} color="error" loading={isDeleteLoading} disabled={!selectedAnnouncement} onClick={_onDelete}>
                            Delete
                        </LoadingButton>
                        <LoadingButton variant="contained" loading={isModifyLoading} disabled={!isModifyValid} onClick={handleModifySubmit(_onSubmitModify)}>
                            Modify
                        </LoadingButton>
                    </Box>
                </Stack>
            </Box>
        </Box>
    )
}