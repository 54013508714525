import { useState } from 'react'
import { Typography, Stack, Divider } from '@mui/material'
import { StyledPaper } from 'components/StyledPaper';
import jsPDF from 'jspdf';
import cbImage from 'cbimage.jpg';
import { API } from 'api';
import { API_ENDPOINTS } from 'util/constants/api';
import { LoadingButton } from '@mui/lab';
import { useMutation, useQueryClient } from 'react-query';
import { useProductData } from 'hooks/lookups.hooks';
import { PermissionBarrier } from 'components/Permissions';
import { ROLES } from 'util/constants/permissions';
import { format, parse } from 'date-fns';
import { DISPLAY_FORMAT, SERVER_FORMAT } from 'util/constants/dates';
import { PRODUCT_FIELDS } from 'components/Modals/products.data';

const ProductDetails = ({product, customAction, currPage = '', hasInvoice = true}) => {
    const queryClient = useQueryClient()
    // const { data: productLookup } = useProductData()

    // const [isLabelsPrinted, setLabelsPrinted] = useState((product.orderChecklist & 2) === 0)

    // const productFields = [
    //     {
    //         field: 'frameColour',
    //         label: 'Frame'
    //     },
    //     {
    //         field: 'model',
    //         label: 'Model',
    //     },
    //     {
    //         field: 'canopyColour',
    //         label: 'Canopy'
    //     },
    //     {
    //         field: 'canopyColourTwo',
    //         label: `${product.model.product === 'Revolvashade' ? 'Second Canopy Colour' : 'Bag Colour'}`,
    //         hide: product.model.product !== 'Revolvashade' && product.model.product !== 'Clevershade'
    //     },
    //     {
    //         field: 'cReference',
    //         label: `Customer Reference`,
    //         hide: currPage !== 'orderBook'
    //     },
    //     {
    //         field: 'valance',
    //         label: 'Valance'
    //     },
    //     {
    //         field: 'base',
    //         label: 'Base'
    //     }
    // ]


    const dataBuilder = (requiredFields, sourceData) => {
        let fields = []

        // const _getFrame = (frameCode) => productLookup.frames.find((frameData) => frameData.code === frameCode)
        // const _getColor = (colorCode) => productLookup.colours.find((colorData) => colorData.code === colorCode)
        
        // const _translateValue = (field) => {
        //     switch(field) {
        //         // Translation here
        //         case 'frameColour':
        //             return _getFrame(product[field].code).displayName

        //         case 'canopyColour':
        //         case 'canopyColourTwo':
        //         case 'valance':

        //             return _getColor(product[field].code).displayName

        //         case 'model': 
        //             return product.model.code

        //         case 'base': 
        //             return product.base.displayName

        //         case 'createdOn':
        //             return format(parse(product.orderDetails.createdOn, SERVER_FORMAT, new Date()), DISPLAY_FORMAT)
                  
        //         default:
        //             return sourceData[field]
        //     }
        // }

        for (const dataFields of Object.values(PRODUCT_FIELDS)) {
            if (!product[dataFields.name]) continue;

            fields.push({
                label: dataFields.label.replace('*', ''), 
                value: product[dataFields.name].displayName
            })
        }

        return fields
    }

    // const _colourFinder = (code, type = 'colours' || 'frames') => {

    //     return productLookup[type].find(colour => colour.code === code).displayName
    // }


    // const createPDF = async () => {
    //     const doc = new jsPDF('l', 'mm', [110, 62]);
    //     const firstColour = product?.canopyColour?.code ? _colourFinder(product.canopyColour.code, 'colours') : 'N/A'
    //     const secondColour = product?.canopyColourTwo?.code ? _colourFinder(product.canopyColourTwo.code, 'colours') : 'N/A'
    //     const valance = product?.valance ? _colourFinder(product.valance.code, 'colours') : 'N/A'
    //     const base = product?.base?.displayName || product?.base
    //     const isBagColour = (product.model.code.includes("BAG")) ? firstColour : "N/A"

    //     doc.addImage(cbImage, 'JPEG', 0, 0, 100, 62);
    //     doc.setFontSize(15);
    //     doc.setTextColor(0, 0, 0);
        
    //     doc.text(2, 6, product.customerName);

    //     doc.text(90, 6, `${product.orderNo}-${product.productIndex}`);
    //     doc.text(2, 12, product.model.displayName);
    //     doc.text(26, 21, product.model.product === 'Bags' ? 'N/A' : firstColour);
    //     doc.text(26, 26, product.model.product === 'Clevershade' ? 'N/A' : secondColour);
    //     doc.text(26, 31, valance);
    //     doc.text(26, 36, (product.model.code.startsWith("CS")) && secondColour !== 'N/A' ? secondColour : isBagColour);
    //     doc.text(2, 54, product?.frameColour ? _colourFinder(product.frameColour.code, 'frames') : 'N/A');

    //     if (base) {
    //         doc.text(50, 54, base);
    //     }

    //     doc.text(13, 59, (product.cReference.length > 10) ? "Check System" : product.cReference);
    //     doc.text(65, 59, new Date(product.dueDate).toLocaleDateString('en-AU'));

    //     doc.autoPrint();
    //     doc.save(`${product.orderNo}.pdf`);
    // }
 
    // const labelsMutation = useMutation(() => {
    //     createPDF()

    //     return API.get(`${API_ENDPOINTS.PRINT_LABELS}?order=${product._id}`)
    // }, {
    //     onSuccess: () => {
    //         queryClient.invalidateQueries('sales')
    //         // False flag because successful bitwise check means not printed
    //         setLabelsPrinted(false) 
    //     }
    // })

    // const _renderActions = () => {
    //     const isPCFormsPrinted = (product.orderChecklist & 1) === 0 
    //     let PC_FORMS_URL = `/api/PCForms?umbrella=${product._id}&orderNo=${product.orderNo}&index=${product.productIndex}`

    //     if (product?.frameColour?.code) {
    //         PC_FORMS_URL += `&frameColour=${product.frameColour.code}`
    //     }
        
    //     return (
    //         <Stack direction="row" spacing={2} >
    //             {currPage === 'sales' && 
    //                 <>
    //                     <LoadingButton 
    //                         onClick={() => queryClient.invalidateQueries('sales')} 
    //                         target="_blank" href={PC_FORMS_URL} 
    //                         variant="contained" 
    //                         color={isPCFormsPrinted ? 'primary' : 'success'} 
    //                         disabled={!hasInvoice}
    //                     >
    //                         {isPCFormsPrinted ? 'PC Forms' : 'Forms Printed'}
    //                     </LoadingButton>

    //                     <LoadingButton 
    //                         onClick={() => labelsMutation.mutate()} 
    //                         loading={labelsMutation.isLoading}
    //                         variant="contained" 
    //                         disabled={!hasInvoice}
    //                         color={isLabelsPrinted ? 'primary' : 'success'}>
    //                         {isLabelsPrinted ? 'Labels' : 'Labels Printed'}
    //                     </LoadingButton>
    //                 </>
    //             }

    //             {customAction}
    //         </Stack>
    //     )
    // }
    
    return (
        <StyledPaper elevation={6} sx={{flex: 1}}>
            <Stack height={'100%'} direction="column" justifyContent="space-between" divider={<Divider flexItem />} spacing={1}>
                <Typography variant={'h5'} sx={{fontWeight: 'bold'}} align='center'>Product Information</Typography>
                
                <Stack spacing={1} alignItems={'flex-start'} sx={{padding: '14px 0'}}>
                    {dataBuilder().map((data, index) => (
                        <Stack key={`${data.label}-${index}`} direction="row" spacing={1} alignItems={'flex-start'} >
                            <Typography sx={{fontWeight: 'bold'}} variant={'body1'}>
                                {data.label}: &nbsp;
                            </Typography>

                            <Typography variant={'body1'}>
                                {data.value}
                            </Typography>
                        </Stack>
                    ))}

                    {/* {dataBuilder(orderFields, product.orderDetails).map((data, index) => (
                        <Stack key={`${data?.label}-${index}`} direction="row" spacing={1} alignItems={'flex-start'} >
                            <Typography sx={{fontWeight: 'bold'}} variant={'body1'}>
                                {data?.label}: &nbsp;
                            </Typography>

                            <Typography variant={'body1'}>
                                {data?.value}
                            </Typography>
                        </Stack>
                    ))} */}

                    {/* {dataBuilder(productFields, product).map((data, index) => (
                        <Stack key={`${index}-${data.label}`} direction="row" spacing={1} alignItems={'flex-start'} >
                            <Typography sx={{fontWeight: 'bold'}} variant={'body1'}>
                                {data.label}: &nbsp;
                            </Typography>

                            <Typography variant={'body1'}>
                                {data.value}
                            </Typography>
                        </Stack>
                    ))} */}
                </Stack>


                {/* {_renderActions()} */}
            </Stack>
        </StyledPaper>
    )
}

export default ProductDetails
