import {
    DialogContent,
    DialogTitle,
    Button,
    Stack,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    List,
    DialogActions,
    ListItem,
    ListItemText,
    IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AddCircleOutlined, ExpandMore, Close, Delete } from '@mui/icons-material';
import { useEffect, useMemo, useState } from 'react';
import { StyledDialog } from 'components/StyledDialog';
import ProductModal from './Products';
import { getProductName, PRODUCT_FIELDS } from './products.data';
import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { useProductDataList } from 'hooks/helper.hooks';
import { listEnquiryProducts } from 'util/helpers/apiHelpers';
import { queryKeys } from 'util/constants/queryKeys';
import { ProductModalType } from 'util/types/enquiry-types';

interface EnquiryProductModalProps {
    isOpen: boolean;
    onClose: () => void;
    modalData: ProductModalType;
}

const EnquiryProductModal = ({ isOpen, onClose, modalData }: EnquiryProductModalProps) => {
    const queryClient = useQueryClient();

    const { enqueueSnackbar } = useSnackbar();

    // Product accordion
    const [isExpanded, setIsExpanded] = useState(false);
    const { productDataList: _productDataList }: any = useProductDataList();

    // Form related states
    const enquiryFormData = useMemo(() => {
        if (!modalData) {
            return null;
        } else if (!modalData.enquiryData) {
            return null;
        }

        const enqData = modalData.enquiryData;

        let processedUmbrella = [];

        if (enqData.umbrellas.length && enqData.umbrellas[0]) {
            processedUmbrella = enqData.umbrellas.map((umbrella: any) => {
                let builder: any = {
                    id: umbrella?.id,
                };

                for (const dataField of Object.values(PRODUCT_FIELDS)) {
                    if (umbrella[dataField.name]) {
                        builder[dataField.name] = _productDataList[dataField.options].find(
                            (targetData: any) => targetData.id === umbrella[dataField.name]
                        );
                    }
                }

                return builder;
            });
        }

        return {
            enq_id: enqData.enquiry_id,
            customerName: enqData.customerName,
            contactNumber: enqData.customerNumber,
            contactEmail: enqData.customerEmail,
            retailer: enqData.assigned_wholesaler,
            dueDate: enqData.dueDate,
            enquiryDetails: enqData.enquiryDetails,
            umbrellas: processedUmbrella,
        };
    }, [modalData, _productDataList]);

    const [productList, setProductList] = useState<any>([]);

    const [currentKey, setCurrentKey] = useState('');

    const [isSubmitLoading, setSubmitLoading] = useState(false);

    const _processedUmbrellas = useMemo(() => {
        if (!enquiryFormData) {
            return [];
        } else if (!enquiryFormData.umbrellas.length) {
            return [];
        }

        const newUmbrellaList = enquiryFormData.umbrellas.map((umbrella: any, index: number) => {
            let builder: any = umbrella;
            builder.indexId = index;
            builder.displayName = getProductName(null, builder.model.code, _productDataList.models);

            return builder;
        });

        return newUmbrellaList;
    }, [enquiryFormData, _productDataList]);

    // Initialize Modal State
    useEffect(() => {
        if (enquiryFormData) {
            if (enquiryFormData.enq_id !== currentKey) {
                setCurrentKey(enquiryFormData.enq_id);
                setProductList(_processedUmbrellas);
            }
        }
    }, [enquiryFormData, _processedUmbrellas, productList]);

    const _onSubmit = async () => {
        if (!enquiryFormData) return;

        const payload = {
            formData: {
                orderID: enquiryFormData.enq_id,
                products: productList.length
                    ? productList.map((product: any) => {
                          let builder: any = {};

                          Object.keys(product).forEach((key) => {
                              builder[key] = product[key]?.code
                                  ? {
                                        code: product[key].code,
                                        id: product[key].id,
                                    }
                                  : product[key];
                          });

                          return builder;
                      })
                    : [],
            },
        };
        setSubmitLoading(true);

        const response = await listEnquiryProducts(payload);

        if (response.success) {
            enqueueSnackbar('Updated Enquiry Products', { variant: 'success' });

            // Run callback if have
            if (modalData.callback && modalData.action) {
                modalData.callback(modalData.action, modalData.enquiryData.enquiry_id);
            } else {
                queryClient.resetQueries(queryKeys.enquiryData);
            }

            onClose();
        } else {
            enqueueSnackbar('Failed to update Enquiry Products', { variant: 'error' });
        }

        setSubmitLoading(false);
    };

    const _handleDelete = (product: any) => {
        // Filter out product to remove
        const newProductList =
            productList.length === 1 ? [] : productList.filter((item: any) => item.indexId !== product.indexId);

        setProductList(newProductList);
    };

    const _handleAddProduct = (data: any) => {
        // Create an indexID to track added product within the UI...
        // Array.length adds an offset of 1 if there's an item
        // This way we can still have 0 index if the list is empty and auto increment
        data.indexId = productList.length;

        setProductList([...productList, data]);
    };

    const _handleModify = (modifiedProduct: any) => {
        setProductList(
            productList.map((item: any) => {
                if (modifiedProduct.indexId === item.indexId) {
                    return modifiedProduct;
                }

                return item;
            })
        );
    };

    const _renderProductList = (product: any, index: number) => {
        let productData = product;

        return (
            <ListItem
                key={index}
                sx={{ pr: '96px' }}
                secondaryAction={
                    <Stack direction="row" spacing={0}>
                        <ProductModal
                            type="modify"
                            initialProductDetails={productData}
                            onProductSubmit={_handleModify}
                            isIconButton
                            buttonProps={{
                                sx: {
                                    marginRight: 1,
                                },
                            }}
                        />

                        <IconButton edge="end" color="error" onClick={() => _handleDelete(productData)}>
                            <Delete />
                        </IconButton>
                    </Stack>
                }
            >
                <ListItemText primary={productData.displayName} />
            </ListItem>
        );
    };

    return (
        <StyledDialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ m: 0, pt: 2, alignItems: 'center', display: 'flex', textTransform: 'capitalize' }}>
                {`Enquiry Products`}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        marginLeft: 'auto',
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Stack direction="column" spacing={2}>
                    <Typography variant="body2">Add, Modify or Delete Products from this Enquiry</Typography>
                </Stack>

                <Accordion
                    sx={{ mt: 1 }}
                    expanded={isExpanded}
                    onChange={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setIsExpanded(!isExpanded);
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        sx={{
                            '& .MuiAccordionSummary-content': {
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            },
                        }}
                        aria-controls="product-panel-content"
                    >
                        <Typography>View Products {productList.length > 0 && `(${productList.length})`}</Typography>

                        <ProductModal
                            type="add"
                            initialProductDetails={null}
                            onProductSubmit={_handleAddProduct}
                            buttonProps={{
                                variant: 'text',
                                startIcon: <AddCircleOutlined />,
                                size: 'small',
                                sx: { mr: 2 },
                            }}
                        />
                    </AccordionSummary>

                    <AccordionDetails>
                        <List dense sx={{ width: '100%' }}>
                            {/* Product Data here */}
                            {!productList.length ? (
                                <ListItem>
                                    <ListItemText primary={'You have yet to add products on this enquiry...'} />
                                </ListItem>
                            ) : (
                                productList.map(_renderProductList)
                            )}
                        </List>
                    </AccordionDetails>
                </Accordion>
            </DialogContent>

            <DialogActions>
                <Button variant="text" sx={{ marginRight: 'auto' }} onClick={onClose}>
                    Cancel
                </Button>
                <LoadingButton variant="contained" loading={isSubmitLoading} onClick={_onSubmit}>
                    {`Apply changes`}
                </LoadingButton>
            </DialogActions>
        </StyledDialog>
    );
};

export default EnquiryProductModal;
