import {useState, useMemo} from 'react';
import {
    Button, DialogContent, DialogActions, DialogTitle,
    TextField , Grid
} from "@mui/material"
import { LoadingButton } from '@mui/lab'
import { useForm, Controller } from 'react-hook-form'
import { StyledDialog } from 'components/StyledDialog';
import { API } from 'api';
import { API_ENDPOINTS } from 'util/constants/api';
import { useSnackbar } from 'notistack';

const FIELDS = {
    customerName: { required: true, label: 'Customer Name', name: 'customerName'},
    customerEmail: { label: 'Customer Email', name: 'customerEmail'},
    mobileNumber: {required: true, label: 'Mobile Number', name: 'mobileNumber'},
    phoneNumber: {label: 'Phone Number', name: 'phoneNumber'},
    address: {required: true, label: 'Address', name: 'address'},
    city: {required: true, label: 'City', name: 'city'},
    state: {required: true, label: 'State', name: 'state'},
    country: {required: true, label: 'Country', name: 'country'},
    postCode: {required: true, label: 'Post Code', name: 'postCode'}
}

const CustomerModal = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const [open, setOpen] = useState(false);

    const { 
        control,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: {
            customerName: "",
            phoneNumber: "",
            address: "",
            state: "",
            country: "",
            customerEmail: "",
            mobileNumber: "",
            city: "",
            postCode: ""
        },
        mode: 'onBlur'
    })

    const [isLoading, setIsLoading] = useState(false)
    const formRules = useMemo(() => ({required: 'This field is required'}), [])

    const _onSubmit = async (formData) => {
        setIsLoading(true)
        const { data } = await API.post(API_ENDPOINTS.CREATE_CLIENT, {formData})
        
        if (data?.success) {
            reset()
            enqueueSnackbar('Customer Added', { variant: 'success'})
        } else {
            enqueueSnackbar('Failed to add customer', { variant: 'error'})
        }

        setIsLoading(false)
    }

    return (
        <>
            <Button variant="contained" onClick={() => setOpen(true)}>Add Customer</Button>
            <StyledDialog
                open={open}
                maxWidth="sm"
                fullWidth
                onClose={() => setOpen(false)}>
                <DialogTitle>Add Customer</DialogTitle>
                <DialogContent>
                    <form id="addCustomer" onSubmit={handleSubmit(_onSubmit)}>
                        <Grid container spacing={2} columnSpacing={1}>
                            {Object.keys(FIELDS).map((key) => {
                                return (
                                    <Grid key={key} item xs={6}>
                                        <Controller 
                                            name={FIELDS[key].name}
                                            control={control}
                                            defaultValue=""
                                            rules={FIELDS[key]?.required && formRules}
                                            render={({ field: {name, value, onChange, onBlur}}) => (
                                                <TextField 
                                                    label={FIELDS[key].label} 
                                                    name={name} 
                                                    value={value} 
                                                    onChange={onChange} 
                                                    onBlur={onBlur}
                                                    error={!!errors[name]} 
                                                    helperText={errors[name]?.message}
                                                />
                                            )}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <LoadingButton 
                        type="submit" 
                        loading={isLoading}
                        disabled={!isValid}
                        form="addCustomer" 
                        variant="contained">
                        Submit
                    </LoadingButton>
                </DialogActions>
            </StyledDialog>
        </>
    )
}

export default CustomerModal
