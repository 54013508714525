import { AddCircle, AddCircleOutline, ChevronRight, Delete, DeleteOutlineOutlined, EditNoteOutlined, EditOutlined, ExpandMore } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Modal, Stack, Typography } from "@mui/material"
import { Details } from "components/Details"
import ProductDetails from "components/Details/ProductDetails"
import { ActionModal } from "components/Modals"
import EnquiryProductModal from "components/Modals/EnquiryProductModal"
import { PRODUCT_FIELDS } from "components/Modals/products.data"
import { PermissionBarrier } from "components/Permissions"
import { StyledPaper } from "components/StyledPaper"
import { useProductDataList } from "hooks/helper.hooks"
import { useSnackbar } from "notistack"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useQueryClient } from "react-query"
import { useProductModal } from "store/modals"
import { ROLES } from "util/constants/permissions"
import { queryKeys } from "util/constants/queryKeys"
import { unlinkEnquiry } from "util/helpers/apiHelpers"

const EnquiryDetails = ({ rowData }) => {    
    const [isModifyModalOpen, setModifyModalOpen] = useState(false)
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
    const [isDeleteLoading, setDeleteLoading] = useState(false)

    const [isModalToggled, setModalToggle] = useState(false)

    const [selectedProduct, setSelectedProduct] = useState(null)

    const [isProductExpanded, setProductExpanded] = useState(false)

    const setProductStoreModalOpen = useProductModal(state => state.setModalOpen)
    const setProductModalData = useProductModal((state) => state.setModalData)
    
    const backdropClick = useRef()
    const queryClient = useQueryClient()

    const { enqueueSnackbar } = useSnackbar()

    const { productDataList } = useProductDataList()

    const enquiryFormData = useMemo(() => {
        const enqData = rowData.original

        let processedUmbrella = []

        if (enqData.umbrellas.length && enqData.umbrellas[0]) {
            processedUmbrella = enqData.umbrellas.map((umbrella) => {
                let builder = {
                    id: umbrella?.id
                }

                for (const dataField of Object.values(PRODUCT_FIELDS)) {
                    if (umbrella[dataField.name]) {
                        builder[dataField.name] = productDataList[dataField.options].find((targetData) => targetData.id === umbrella[dataField.name])
                    }
                }

                return builder
            })
        }

        return {
            enq_id: enqData.enquiry_id,
            customerName: enqData.customerName,
            contactNumber: enqData.customerNumber,
            contactEmail: enqData.customerEmail,
            retailer: enqData.assigned_wholesaler,
            dueDate: enqData.dueDate,
            enquiryDetails: enqData.enquiryDetails,
            umbrellas: processedUmbrella,
            status: enqData.status
        }
    }, [rowData.original, productDataList])

    const handleEnquiryProductOpen = () => {
        const modalPayload = {
            enquiryData: rowData.original
        }

        setProductStoreModalOpen(true)
        setProductModalData(modalPayload)
    }

    const handleEnquiryDelete = async () => {
        setDeleteLoading(true)

        try {
            const response = await unlinkEnquiry(enquiryFormData.enq_id)

            if (response.success) {
                enqueueSnackbar('Enquiry unlinked', {variant: 'success'})
                queryClient.invalidateQueries(queryKeys.enquiryData)
            }
        } catch (e) {
            enqueueSnackbar('Failed to unlink enquiry', {variant: 'error'})
            console.log(e)
        } finally {
            setDeleteLoading(false)
        }
    }

     const _handleMouseClick = (event) => {
        // We don't want to close the dialog when clicking the dialog content.
        // Make sure the event starts and ends on the same DOM element.
        backdropClick.current = event.target === event.currentTarget
    }

    const _handleBackdropClick = (event) => {
        if (!backdropClick.current) {
            return
        }

        backdropClick.current = null

        _handleModalClose(event, 'backdropClick')
    }

    const _handleModalClose = (event, reason) => {
        setModalToggle(!isModalToggled)
        setSelectedProduct(null)
    }

    const _renderUmbrellas = (umbrella, index) => {

        const handleProductClick = () => {
            setModalToggle(!isModalToggled)
            setSelectedProduct(umbrella)
        }

        return (
        <ListItem key={index}>
            <ListItemButton disableTouchRipple onClick={handleProductClick}>
                <ListItemIcon>
                    <ChevronRight />
                </ListItemIcon>
                <ListItemText primary={umbrella.model.displayName} />
            </ListItemButton>
        </ListItem>
        )
    }

    return (
        <StyledPaper elevation={6} sx={{flex: 1, maxWidth: 720}}>
            <Stack height={'100%'} direction="column" justifyContent="flex-start" divider={<Divider flexItem />} spacing={1}>
                <Typography variant={'h6'} sx={{fontWeight: 'bold'}} align="center">Enquiry Details</Typography>

                <Stack spacing={4} alignItems={'flex-start'} sx={{padding: '14px 0', width: '100%'}}>
                   <Typography variant={'body2'} sx={{whiteSpace: 'pre-wrap'}}>
                        {enquiryFormData.enquiryDetails}
                   </Typography>

                    { enquiryFormData.umbrellas.length ? (
                        <Accordion sx={{width: '100%'}} expanded={isProductExpanded} onChange={() => setProductExpanded(!isProductExpanded)}>
                            <AccordionSummary 
                                expandIcon={<ExpandMore />} 
                                sx={{
                                    '& .MuiAccordionSummary-content': {
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }
                                }}
                                aria-controls="product-panel-content" 
                            >
                                <Typography>
                                    View Umbrellas
                                </Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                <List dense sx={{width: '100%'}}>
                                    {enquiryFormData.umbrellas.map(_renderUmbrellas)}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    ) : <></>}
                </Stack>

               { !enquiryFormData.status.converted && (
                 <Box>
                    <Stack direction="row" spacing={2} justifyContent="center">
                        <PermissionBarrier scopes={[ROLES.admin, ROLES.sales]}>
                            <Button disabled={!enquiryFormData.status.contacted} onClick={handleEnquiryProductOpen} variant="text" startIcon={<EditNoteOutlined />}>
                                {`Enquiry Products`}
                            </Button>
                            <Button onClick={() => setModifyModalOpen(true)} variant="text" startIcon={<EditOutlined />}>
                                {`Modify Enquiry`}
                            </Button>

                            <ActionModal.Simple 
                                isOpen={isDeleteModalOpen}
                                modalTitle="Unlink Enquiry"
                                dialogMsg='Are you sure you want to unlink this enquiry?'
                                isLoading={isDeleteLoading}
                                onConfirm={handleEnquiryDelete}
                                btnText="Unlink Enquiry"
                                buttonProps={{
                                    color: 'error',
                                    startIcon: <DeleteOutlineOutlined />
                                }}
                            />
                        </PermissionBarrier>
                    </Stack>
                </Box>
               ) }
            </Stack>    

            <ActionModal.EnquiryForm 
                isOpen={isModifyModalOpen} 
                onClose={() => setModifyModalOpen(false)} 
                formData={enquiryFormData}
                isModify
            />

            <Modal 
                open={isModalToggled} 
                onClose={_handleModalClose}
                onClick={_handleBackdropClick}
                >
                <Container 
                    onMouseDown={_handleMouseClick} 
                    maxWidth="sm" 
                    sx={{
                        display: 'flex', 
                        alignItems: 'center', 
                        height: '100%', 
                        outline: 0
                    }}>
                    <ProductDetails 
                        product={selectedProduct}
                        
                        // customAction={_renderCustomAction()}
                        // hasInvoice={!!row.invNo}
                    />
                </Container>
            </Modal>
        </StyledPaper>
    )
}

export default EnquiryDetails