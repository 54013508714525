import { TableCell, TableRow, IconButton, Collapse, Stack, Typography, Chip, Tooltip, Button, Tab, Tabs, Box } from '@mui/material'
import { KeyboardArrowUp, KeyboardArrowDown, ErrorOutline, Check } from '@mui/icons-material';
import { useMemo, useState, memo, useCallback } from 'react';
import { format, parse } from 'date-fns';
import { DISPLAY_FORMAT, SERVER_FORMAT } from 'util/constants/dates';

import { ActionModal } from 'components/Modals';
import { Details } from 'components/Details';
import { TabPanel } from 'components/TabPanel';
import { StyledPaper } from 'components/StyledPaper';
import useImageStore from 'store/images'

const HistoryRow = (props) => {
    const { row } = props
    const [open, setOpen] = useState(false)
    const [isImgListOpen, setImgListOpen] = useState(false)
    const [selectedTab, setSelectedTab] = useState(0)
    const dueDate = format(parse(row.dueDate, SERVER_FORMAT, new Date()), DISPLAY_FORMAT)
    
    const setModalToggle = useImageStore((state) => state.setModalToggle)
    const setSelectedOrderNo = useImageStore((state) => state.setSelectedOrderNo)


    const handleImageModal = useCallback((event) => {
        event.stopPropagation();

        setModalToggle(true)
        setSelectedOrderNo(row.orderNo)
    }, [row.orderNo, setModalToggle, setSelectedOrderNo])

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
    }

    const propData = [
        row.orderNo,
        row.customer,
        row.clientNo,
        row.jobDescription,
        <Button onClick={handleImageModal}>
            Images
        </Button>,
        dueDate
    ]

    return (
        <>
            <TableRow 
                selected={open} 
                key={`${row.orderNo}-parent`}
                onClick={() => setOpen(!open)}>
                <TableCell sx={{borderBottom: 0}} id={row.orderNo}>
                    <IconButton
                        align='center'
                         aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                {/* Regular Data Table */}
                {propData.map((curRow, index) => {
                    return <TableCell sx={{borderBottom: 0, minWidth: 125}} align='left' key={`regTable-${index}`}>{curRow}</TableCell>
                })}
            </TableRow>
            <TableRow selected={open}>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                            {/* <Details.Order row={row} /> */}

                            {/* <Details.Umbrellas order={row} /> */}

                            <Box
                                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', margin: 1, borderRadius: 2, padding: 2 }}
                                >
                                <Tabs
                                    orientation="vertical"
                                    variant="standard"
                                    value={selectedTab}
                                    onChange={handleTabChange}
                                    aria-label="Vertical tabs example"
                                    sx={{ borderRight: 1, borderColor: 'divider' }}
                                >
                                    <Tab label="Despatch Items" id={`tab-1-${row.orderNo}`} />
                                    <Tab label="Tickets Info" id={`tab-2-${row.orderNo}`} />
                                    
                                </Tabs>
                                <TabPanel value={selectedTab} index={0} style={{flex: 1, alignItems: 'center'}}>
                                    <Stack direction="row" sx={{flexWrap: 'wrap'}} justifyContent="center"> 
                                        {row.despatchItems.length ? row.despatchItems.map((item, index) => {
                                            return (
                                                <Details.DespatchItems 
                                                    key={index}
                                                    itemDetails={item}
                                                />
                                            )
                                        }) : <>
                                            <StyledPaper elevation={6} sx={{flex: 1}}>
                                                <Stack height={'100%'} direction="column" justifyContent="flex-start" spacing={1}>
                                                    <Typography>No tickets found.</Typography>
                                                </Stack>
                                            </StyledPaper>
                                        </>}
                                    </Stack>
                                </TabPanel>
                                <TabPanel value={selectedTab} index={1} style={{flex: 1, alignContent: 'center', justifyContent: 'center'}}>
                                    <Stack direction="row" sx={{width: '100%', flexWrap: 'wrap'}} justifyContent="center" alignContent={'center'}> 
                                        <Details.Tickets row={row} currPage={props.curPage}/>
                                    </Stack>
                                </TabPanel>
                                
                            </Box>
                    </Collapse>
                </TableCell>
    
            </TableRow>
        </>
    )
}

export default memo(HistoryRow)