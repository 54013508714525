import { useState, useMemo, useEffect } from 'react';
import {
    Button,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Grid,
    IconButton,
    Autocomplete,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm, Controller } from 'react-hook-form';
import { StyledDialog } from 'components/StyledDialog';
import { useSnackbar } from 'notistack';
import { Close } from '@mui/icons-material';
import { useQueryClient } from 'react-query';
import { queryKeys } from 'util/constants/queryKeys';
import { createEnquiry, enquiryFeedback, updateEnquiry } from 'util/helpers/apiHelpers';
import { FeedbackModalDataType } from 'util/types/enquiry-types';

interface EnquiryFeedbackModalProps {
    isOpen: boolean;
    onClose: () => void;
    storeData?: FeedbackModalDataType;
}

type FeedbackFormType = {
    feedbackDetails: string;
};

const EnquiryFeedbackModal = ({ isOpen, onClose, storeData }: EnquiryFeedbackModalProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm<FeedbackFormType>({
        defaultValues: {
            feedbackDetails: '',
        },
        mode: 'onBlur',
    });

    const [isLoading, setIsLoading] = useState(false);

    const formRules = useMemo(() => ({ required: 'This field is required' }), []);

    const labelText = useMemo(() => {
        if (storeData) {
            const action = storeData.action;
            if (action?.tag === 'contacted' && action.value) {
                return 'Let us know how your talk with the customer went:';
            }
        }

        return 'Let us know what went wrong:';
    }, [storeData]);

    const _onSubmit = async (formData: FeedbackFormType) => {
        setIsLoading(true);

        const payload = {
            formData: {
                comment: formData.feedbackDetails,
            },
        };

        try {
            let response = await enquiryFeedback(payload, storeData?.enq_id);

            if (response.success) {
                // run side effect
                if (storeData) {
                    storeData.callback(storeData.action, storeData.enq_id);
                } else {
                    queryClient.resetQueries([queryKeys.enquiryData]);
                }
                enqueueSnackbar(`Feedback added`, { variant: 'success' });
                handleClose();
            } else {
                enqueueSnackbar(`Failed to add feedback`, { variant: 'error' });
            }
        } catch (e) {
            console.log(e, ';; error');
            enqueueSnackbar(`Failed to add feedback`, { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        onClose();

        reset();
    };

    return (
        <StyledDialog open={isOpen} maxWidth="sm" fullWidth onClose={handleClose}>
            <DialogTitle sx={{ m: 0, pt: 2, alignItems: 'center', display: 'flex', textTransform: 'capitalize' }}>
                {`Enquiry Feedback`}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        marginLeft: 'auto',
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form id="feedbackForm" onSubmit={handleSubmit(_onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body2">{labelText}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Controller
                                name={'feedbackDetails'}
                                control={control}
                                defaultValue=""
                                rules={formRules}
                                render={({ field: { name, value, onChange, onBlur } }) => (
                                    <TextField
                                        label={'Feedback'}
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        fullWidth
                                        minRows={4}
                                        multiline
                                        error={!!errors[name]}
                                        helperText={errors[name]?.message}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="caption" sx={{ opacity: 0.7 }}>
                                Action will not update if a feedback is not submitted
                            </Typography>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton type="submit" loading={isLoading} disabled={!isValid} form="feedbackForm" variant="contained">
                    Submit
                </LoadingButton>
            </DialogActions>
        </StyledDialog>
    );
};

export default EnquiryFeedbackModal;
