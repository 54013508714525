import { useState } from 'react'
import { 
    Typography, 
    Stack, 
    Accordion, 
    AccordionSummary, 
    AccordionDetails, 
    Box,
} from '@mui/material'

import { StyledPaper } from 'components/StyledPaper';
import { ExpandMore} from '@mui/icons-material';

import ProgressBar from 'components/Loaders/ProgressBar';

const TicketDetails = ({row, customAction, currPage = '', ...props}) => {
    const [selectedTicket, setSelectedTicket] = useState(-1)

    const handleExpand = (ticketIndex) => (event, isExpanded) => {
        setSelectedTicket(isExpanded ? ticketIndex : -1)
    }

    const ticketDataBuilder = (sourceData) => {
        const requiredFields = [
            {
                field: 'partCode',
                label: 'Part Code'
            },
            {
                field: 'partDescription',
                label: 'Description'
            },
            {
                field: 'partDueDate',
                label: 'Due Date'
            },
            {
                field: 'jobStatus',
                label: 'Status'
            },
            {
                field: 'ticketProgressPercent',
                label: 'Progress'
            }
        ]

        return requiredFields.map((data) => {
            return (
                <Stack key={data.field} direction='row' spacing={1} sx={{pt: 1}} alignItems={'center'}>
                    <Typography sx={{fontWeight: 'bold'}} variant={'body1'}>
                        {data.label}: &nbsp;
                    </Typography>

                    {data.field === 'ticketProgressPercent' ? (
                        <Box sx={{minWidth: 125}}>
                            <ProgressBar progressPercent={sourceData[data.field]} dueDate={sourceData.partDueDate}/>
                        </Box>
                    ) : (
                        <Typography variant={'body1'} sx={{textAlign: 'left', maxWidth: 500 }}>
                            {sourceData[data.field]}
                        </Typography>
                    )}
                </Stack>
            )
        })
    }

    return (
        <StyledPaper elevation={6} sx={{flex: 1}}>
            <Stack height={'100%'} direction="column" justifyContent="flex-start" spacing={1}>
                <Typography variant={'h6'} sx={{fontWeight: 'bold'}} align="center">Tickets Information</Typography>

                {row.tickets.length > 0 ? row.tickets.map((ticketData, index) => (
                    <Accordion 
                        key={ticketData.partCode + row.orderNo + index} 
                        expanded={selectedTicket === index} 
                        onChange={handleExpand(index)}
                    >
                        <AccordionSummary 
                            expandIcon={<ExpandMore />} 
                            sx={{
                                '& .MuiAccordionSummary-content': {
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }
                            }}
                            aria-controls="product-panel-content" 
                        >
                            <Typography>
                                {ticketData.partCode}
                            </Typography>

                            <Box sx={{minWidth: 100, mr: 3}}>
                                <ProgressBar progressPercent={ticketData.ticketProgressPercent} dueDate={ticketData.partDueDate}/>
                            </Box>
                        </AccordionSummary>

                        <AccordionDetails>
                            {ticketDataBuilder(ticketData)}
                        </AccordionDetails>
                    </Accordion>
                )): 
                
                (<Typography>No tickets found.</Typography>) }
                
            </Stack>
        </StyledPaper>
    )
}

export default TicketDetails
