import { useMemo } from 'react';
import { useColours, useFrames, useModelData } from './lookups.hooks';

export const useProductDataList = () => {
    const { data: modelsDataLookup, isLoading: isModelsLoading } = useModelData();
    const { data: framesLookup, isLoading: isFramesLoading } = useFrames();
    const { data: coloursLookup, isLoading: isColoursLoading } = useColours();

    const _productDataList = useMemo(() => {
        if (isModelsLoading || isFramesLoading || isColoursLoading) {
            return {
                models: [],
                frames: [],
                colours: [],
                bases: [],
            };
        }

        return {
            models: modelsDataLookup.models,
            frames: framesLookup.frames,
            colours: coloursLookup.colours,
            bases: modelsDataLookup.models.filter((model) => model.displayName.toLowerCase().includes('base')),
        };
    }, [modelsDataLookup, framesLookup, coloursLookup, isModelsLoading, isFramesLoading, isColoursLoading]);

    return { productDataList: _productDataList };
};
