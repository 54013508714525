import { useEffect, useState } from 'react'
import { TextField, Typography, Box, Stack, Divider, Autocomplete, MenuItem, Grid, Checkbox, ListItemText } from '@mui/material'
import { useColours, useProductData } from 'hooks/lookups.hooks'
import { useForm, Controller} from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { API } from 'api'
import { API_ENDPOINTS } from 'util/constants/api'
import { useSnackbar } from 'notistack'

const COLOR_RANGE = ['Standard', 'Non-Standard', 'Premium']
const PRODUCT_RANGE = ['Revolvashade', 'Clevershade']

export const Colours = () => {
    // const { data: productLookupData, refetch: refetchLookup } = useProductData()
    const { data: coloursLookup, refetch: refetchColoursLookup} = useColours()
    const { enqueueSnackbar } = useSnackbar()
    
    const [selectedColour, setSelectedColour] = useState(null)
    const [isCreateLoading, setCreateLoading] = useState(false)
    const [isModifyLoading, setModifyLoading] = useState(false)

    const { 
        control: createControl,
        handleSubmit: handleCreateSubmit,
        formState: { errors: createErrors, isValid: isCreateValid },
        reset: resetCreate
    } = useForm({
        defaultValues: {
            code: '',
            displayName: '',
            range: '',
            product: []
        },
        mode: 'onBlur'
    })

    const {
        control: modifyControl,
        handleSubmit: handleModifySubmit,
        formState: { errors: modifyErrors, isValid: isModifyValid },
        resetField,
        setValue,
        reset: resetModify
    } = useForm({
        defaultValues: {
            code: '',
            displayName: '',
            range: '',
            product: []
        },
        mode: 'onBlur'
    })

    const formRules = {required: 'This field is required'}

    const _handleColourChange = (event, param) => {
        setSelectedColour(param)
    }

    const _onSubmitCreate = async (newData) => {
        // API here...
        setCreateLoading(true)

        const { data } = await API.post(API_ENDPOINTS.CREATE_COLOUR, { formData: newData })

        if (data.success) {
            enqueueSnackbar(data.message, { variant: 'success' })
            refetchColoursLookup()
            resetCreate()
        } else {
            enqueueSnackbar(data.message, { variant: 'error'})
        }

        setCreateLoading(false)
    }

    const _onSubmitModify = async (newData) => {
        setModifyLoading(true)

        const payload = {
            ...newData,
        }
        
        const { data } = await API.put(`${API_ENDPOINTS.MODIFY_COLOUR}/${selectedColour.id}`, { formData: payload})

        if (data.success) {
            enqueueSnackbar(data.message, { variant: 'success' })
            refetchColoursLookup()
            resetModify()
            setSelectedColour(null)
        } else {
            enqueueSnackbar(data.message, { variant: 'error'})
        }

        
        setModifyLoading(false)
    }

    useEffect(() => {
        if (selectedColour) {
            setValue('code', selectedColour?.code, { shouldValidate: true, shouldDirty: true})
            setValue('displayName', selectedColour?.displayName, { shouldValidate: true, shouldDirty: true})
            setValue('range', selectedColour?.range, { shouldValidate: true, shouldDirty: true})
            setValue('product', selectedColour?.product, { shouldValidate: true, shouldDirty: true })
        } else {
            resetField('code')
            resetField('displayName')
            resetField('range')
            resetField('product')
        }
    }, [selectedColour, resetField, setValue])

    const _renderSelectRange = ({ field, formState: { errors } }) => {
        return (
            <TextField 
                key={field.name} 
                name={field.name}
                value={field.value || ''}
                onChange={field.onChange}
                onBlur={field.onBlur}
                sx={{textAlign: 'left'}}
                select 
                fullWidth 
                label="Range" 
                error={!!errors[field.name]} 
                SelectProps={{
                    onClose: () => { setTimeout(() => document.activeElement.blur(), 0)}
                }}
                helperText={errors[field.name]?.message}>
                {COLOR_RANGE.map((option, index) => (
                    <MenuItem key={index} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    const _renderProductRange = ({ field, formState: { errors }}) => {
        return (
            <TextField 
                key={field.name} 
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                sx={{textAlign: 'left'}}
                select 
                fullWidth 
                label="Product" 
                SelectProps={{
                    multiple: true,
                    renderValue: (selected) => selected.join(', '),
                    onClose: () => { setTimeout(() => document.activeElement.blur(), 0)}
                }}
                error={!!errors[field.name]} 
                helperText={errors[field.name]?.message}>
                {PRODUCT_RANGE.map((option, index) => (
                    <MenuItem key={index} value={option}>
                        <Checkbox checked={field.value.indexOf(option) > -1} />
                        <ListItemText primary={option}/>
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    return (
        <Box>
            <Stack direction="column" spacing={1}> 
                <Typography variant="h5" sx={{fontWeight: 'bold', textAlign: 'left'}}>
                    Create Colour
                </Typography>
                <Typography sx={{color: 'text.secondary', textAlign: 'left'}}>
                    Create and define a new colour
                </Typography>

                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={6} container spacing={2}>
                            <Grid item xs={12}>
                                <Controller 
                                    name="code"
                                    control={createControl}
                                    rules={formRules}
                                    render={({field: {name, value, onChange, onBlur}}) => 
                                        <TextField 
                                            name={name}
                                            value={value} 
                                            onChange={onChange} 
                                            onBlur={onBlur} 
                                            label={'Code'}
                                            type="text"
                                            fullWidth
                                            error={!!createErrors[name]} 
                                            helperText={createErrors[name]?.message} 
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller 
                                    name="displayName"
                                    control={createControl}
                                    rules={formRules}
                                    render={({field: {name, value, onChange, onBlur}}) => 
                                        <TextField 
                                            name={name}
                                            value={value} 
                                            onChange={onChange} 
                                            onBlur={onBlur} 
                                            label={'Name'}
                                            fullWidth
                                            error={!!createErrors[name]} 
                                            helperText={createErrors[name]?.message} 
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={6} container spacing={2}>
                            <Grid item xs={12}>
                                <Controller 
                                    name="range"
                                    control={createControl}
                                    rules={formRules}
                                    render={_renderSelectRange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller 
                                    name="product"
                                    control={createControl}
                                    rules={formRules}
                                    render={_renderProductRange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{alignSelf: 'flex-end', pt: 2}}>
                    <LoadingButton loading={isCreateLoading} disabled={!isCreateValid} variant="contained" onClick={handleCreateSubmit(_onSubmitCreate)}>
                        Create
                    </LoadingButton>
                </Box>
            </Stack>

            <Divider sx={{mt: 4, mb: 4}}/>

            <Stack direction="column" spacing={2}> 
                <Typography variant="h5" sx={{fontWeight: 'bold', textAlign: 'left'}}>
                    Modify Colour
                </Typography>
                <Typography sx={{color: 'text.secondary', textAlign: 'left'}}>
                    Modify an existing colour
                </Typography>
                
                <Autocomplete 
                    name={'colours'}
                    value={selectedColour}
                    onChange={_handleColourChange}
                    clearOnBlur
                    handleHomeEndKeys
                    options={coloursLookup?.colours.sort((a, b) => b.range.localeCompare(a.range)) || []}
                    groupBy={(option) => option?.range}
                    getOptionLabel={(option) => option.displayName}
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    renderOption={(props, option) => <li {...props} key={option._id}>{option.displayName}</li>}
                    renderInput={(params) => <TextField {...params} label={'Colours'} />}
                />
                
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={6} container spacing={2}>
                            <Grid item xs={12}>
                                <Controller 
                                    name="code"
                                    control={modifyControl}
                                    rules={formRules}
                                    render={({field: {name, value, onChange, onBlur}}) => 
                                        <TextField 
                                            name={name}
                                            value={value} 
                                            onChange={onChange} 
                                            onBlur={onBlur} 
                                            label={'Code'}
                                            type="text"
                                            fullWidth
                                            error={!!modifyErrors[name]} 
                                            helperText={modifyErrors[name]?.message} 
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller 
                                    name="displayName"
                                    control={modifyControl}
                                    rules={formRules}
                                    render={({field: {name, value, onChange, onBlur}}) => 
                                        <TextField 
                                            name={name}
                                            value={value} 
                                            onChange={onChange} 
                                            onBlur={onBlur} 
                                            label={'Name'}
                                            fullWidth
                                            error={!!modifyErrors[name]} 
                                            helperText={modifyErrors[name]?.message} 
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={6} container spacing={2}>
                            <Grid item xs={12}>
                                <Controller 
                                    name="range"
                                    control={modifyControl}
                                    rules={formRules}
                                    render={_renderSelectRange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller 
                                    name="product"
                                    control={modifyControl}
                                    rules={formRules}
                                    render={_renderProductRange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{alignSelf: 'flex-end', pt: 2}}>
                    <LoadingButton loading={isModifyLoading} disabled={!isModifyValid} variant="contained" onClick={handleModifySubmit(_onSubmitModify)}>
                        Modify
                    </LoadingButton>
                </Box>
            </Stack>
        </Box>
    )
}