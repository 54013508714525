import create from 'zustand'
import { mutator } from 'util/helpers/mutator'

const initialState = {
    hideOceanicOrders: false
}

export default create((set) => ({
    ...initialState,

    // MUTATIONS
    setOceanicOrdersHidden: mutator(set, 'hideOceanicOrders')
}))