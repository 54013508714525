import { Skeleton } from "@mui/material"

const SkeletonLoader = () => {
    return (
        <>
            <Skeleton variant="rectangular" height={120} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
        </>
    )
}

export default SkeletonLoader