export const mapObjectData = (sourceObj, targetObj, fieldObj = {}) => {
    for (const field of Object.values(fieldObj)) {
        let sourceValue = null

        if (sourceObj) {
            sourceValue = sourceObj[field.name]
        }

        targetObj[field?.name] = sourceValue
    }

    return targetObj
}
