import { useState } from 'react'
import { 
    Typography, 
    Stack, 
    Accordion, 
    AccordionSummary, 
    AccordionDetails, 
    Box,
} from '@mui/material'

import { StyledPaper } from 'components/StyledPaper';
import { ExpandMore} from '@mui/icons-material';

import ProgressBar from 'components/Loaders/ProgressBar';

const DespatchItemDetails = ({itemDetails, ...props}) => {
    return (
        <StyledPaper elevation={6} sx={{width: 300}}>
            <Stack height={'100%'} direction="column" justifyContent="flex-start" spacing={1} alignContent={'center'}>
                <Typography variant={'h6'} sx={{fontWeight: 'bold'}} align="center">{itemDetails.partCode}</Typography>
                    <Typography variant={'body1'} sx={{textAlign: 'left', maxWidth: 500, fontWeight: 'bold' }}>
                        Quantity: {itemDetails.remQty}
                    </Typography>
                    
                    <Typography variant={'body1'} sx={{textAlign: 'left', maxWidth: 500 }}>
                        {itemDetails.partDescription}
                    </Typography>
            </Stack>
        </StyledPaper>
    )
}

export default DespatchItemDetails
