import { useState } from 'react'
import { 
    Typography, 
    Stack, 
    Accordion, 
    AccordionSummary, 
    AccordionDetails, 
    Box,
} from '@mui/material'

import { StyledPaper } from 'components/StyledPaper';
import { ExpandMore} from '@mui/icons-material';

import ProgressBar from 'components/Loaders/ProgressBar';

const TicketComments = ({ticketList, row}) => {
    const [selectedTicket, setSelectedTicket] = useState(-1)

    const handleExpand = (ticketIndex) => (event, isExpanded) => {
        setSelectedTicket(isExpanded ? ticketIndex : -1)
    }

    return (
        <StyledPaper elevation={6} sx={{flex: 1}}>
            <Stack height={'100%'} direction="column" justifyContent="flex-start" spacing={1}>
                <Typography variant={'h6'} sx={{fontWeight: 'bold'}} align="center">Tickets Comments</Typography>

                {ticketList.length > 0 ? ticketList.map((ticketData, index) => (
                    <Accordion 
                        key={ticketData.partCode + row.orderNo + index} 
                        expanded={selectedTicket === index} 
                        onChange={handleExpand(index)}
                    >
                        <AccordionSummary 
                            expandIcon={<ExpandMore />} 
                            sx={{
                                '& .MuiAccordionSummary-content': {
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }
                            }}
                            aria-controls="product-panel-content" 
                        >
                            <Typography>
                                {ticketData.partCode}
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography variant={'body1'} sx={{textAlign: 'center'}}>No Comments Found.</Typography>
                        </AccordionDetails>
                    </Accordion>
                )): 
                
                (<Typography>No tickets found.</Typography>) }
                
            </Stack>
        </StyledPaper>
    )
}

export {TicketComments}
