import { LoadingButton } from "@mui/lab"
import { COLORS } from "util/constants/commons"
import { Close, Delete } from "@mui/icons-material"
import {styled} from '@mui/material/styles'
import { Button, DialogTitle, IconButton, DialogContent, DialogActions, Box, ImageList, ImageListItemBar, ImageListItem, Slide} from "@mui/material"
import { TransitionProps } from '@mui/material/transitions';

import { StyledDialog } from "components/StyledDialog"
import {forwardRef} from 'react'


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
  

const ImagePreviewModal = ({imgData, isOpen, onClose }) => {
    

    return (
        <div>
            <StyledDialog TransitionComponent={Transition} fullScreen open={isOpen} onClose={(event) => {
                onClose()
                event.stopPropagation()
            }}>
                <DialogTitle sx={{m: 0, pt: 2, alignItems: 'center', display: 'flex'}}>
                    Image Preview
                    <IconButton
                        aria-label="close"
                        onClick={() => onClose()}
                        sx={{
                            marginLeft: 'auto',
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>

                <DialogContent sx={{background: COLORS.neutral_gray}}>
                    <Box style={{display: 'flex', justifyContent: 'center'}}>
                        <img
                            srcSet={`${imgData.img}?fit=crop&auto=format&dpr=2 2x`}
                            src={`${imgData.img}?fit=crop&auto=format&dpr=2 2x`}
                            alt={imgData.imageName}
                            style={{maxWidth: '100%', height: 'auto'}}
                            loading="lazy"
                        />
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button variant="text" sx={{marginRight: 'auto'}} onClick={() => onClose()}>
                        Close
                    </Button>
                </DialogActions>
            </StyledDialog>
        </div>
    )
}

export default ImagePreviewModal